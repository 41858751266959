.options {
	position: relative;
	cursor: pointer;
	text-align: center;
}

.options .button {
	font-size: 12px;
	vertical-align: middle;
}

.options::before .reactions {
	opacity: 1;
	transition: 0.1s 0.5s;
}

.options .reactions .icon-container:hover {
	transform: scale(1.3, 1.3) translateY(-5px);
}

.options:hover .reactions {
	opacity: 1;
}

.options.default {
	color: #7f7f7f !important;
}

.options.default::before {
	background-position: 0 -893px;
}

.options.Like {
	color: #5890ff;
}

.options.Like::before {
	background-position: 0 -922px;
}

.options.Love::before {
	background-position: 0 -710px;
}

.options.Thankful::before {
	background-position: 0 -642px;
}

.options.Haha::before {
	background-position: 0 -795px;
}

.options.Wow::before {
	background-position: 0 -676px;
}

.options.Sad::before {
	background-position: 0 -744px;
}

.options.Angry::before {
	background-position: 0 -608px;
}

.options .reactions {
	position: absolute;
	bottom: 130%;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 50px;
	background: white;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.15);
	line-height: 0;
	white-space: nowrap;
	opacity: 0;
	padding: 3px;
	pointer-events: none;
	transition: 0.2s 0.5s;
}

.options .reactions .icon-container {
	display: inline-block;
	transition: transform 0.2s ease;
}

.options .reactions .reaction {
	display: inline-block;
	height: 48px;
	width: 48px;
	transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
	transition: transform 0s 0.7s ease, opacity 0s 0.7s ease;
	line-height: initial;
	opacity: 0;
	border-radius: 100%;
	background-image: url(../images/facebook-reactions.gif);
	background-repeat: no-repeat;
	background-size: auto;
}

.options .reactions .reaction.is-visible {
	transition: opacity 0.3s ease;
	opacity: 1;
	pointer-events: all;
	-webkit-animation: testes 0.6s ease forwards;
	animation: testes 0.6s ease forwards;
}

@-webkit-keyframes testes {
	0% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 70px);
	}
	30% {
		transform: perspective(1px) scale(0.87, 0.87) translate(0, -4px);
	}
	70% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 1px);
	}
	100% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
	}
}

@keyframes testes {
	0% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 70px);
	}
	30% {
		transform: perspective(1px) scale(0.87, 0.87) translate(0, -4px);
	}
	70% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 1px);
	}
	100% {
		transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
	}
}

.options .reactions .reaction.is-visible:hover {
	transform: perspective(1px) scale(1, 1) translate(0, -5px);
}

.options .reactions .reaction.is-visible:hover::after {
	opacity: 1;
}

.options .reactions .reaction::after {
	content: attr(data-popup);
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 15px;
	color: white;
	font-family: helvetica;
	font-weight: bold;
	font-size: 13px;
	padding: 2px 7px;
	top: -25px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.07s ease;
}

.options .reactions .reaction.like {
	background-position: 0 0;
}

.options .reactions .reaction.love {
	background-position: -52px 0;
}

.options .reactions .reaction.thankful {
	background-position: -314px 0;
}

.options .reactions .reaction.haha {
	background-position: -156px 0;
}

.options .reactions .reaction.wow {
	background-position: -104px 0;
}

.options .reactions .reaction.sad {
	background-position: -208px 0;
}

.options .reactions .reaction.angry {
	background-position: -260px 0;
}