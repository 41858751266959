.chat-wrapper {
	width: auto;
	height: 75vh;
	overflow: hidden;
	border-radius: .25rem;
	position: relative;
	background: #fff;
	box-shadow: 0 .1rem .7rem rgba(0, 0, 0, .1)
}
.chat-sidebar {
	width: 340px;
	height: 100%;
	position: absolute;
	background: #fff;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	overflow: hidden;
	border-right: 1px solid rgba(0, 0, 0, .125);
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}
.chat-sidebar-header {
	width: auto;
	height: auto;
	position: relative;
	background: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, .125);
	border-right: 0 solid rgba(0, 0, 0, .125);
	border-top-left-radius: .25rem;
	padding: 15px
}
.chat-sidebar-content {
	padding: 0
}
.chat-user-online {
	position: relative
}
.chat-user-online img {
	object-fit: cover;
}
.chat-sidebar-header .chat-user-online:before {
	content: '';
	position: absolute;
	bottom: 7px;
	left: 29px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	background: #16e15e
}
.chat-list .chat-user-online:before {
	content: '';
	position: absolute;
	bottom: 7px;
	left: 26px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	background: #16e15e
}
.chat-user-offline {
   position: relative
}
.chat-user-offline img {
   object-fit: cover;
}
.chat-sidebar-header .chat-user-offline:before {
   content: '';
   position: absolute;
   bottom: 7px;
   left: 29px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   box-shadow: 0 0 0 2px #fff;
   background: var(--grayColor2);
}
.chat-list .chat-user-offline:before {
   content: '';
   position: absolute;
   bottom: 7px;
   left: 26px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   box-shadow: 0 0 0 2px #fff;
   background: var(--grayColor2);
}
.chat-content {
	margin-left: 340px;
	padding: 85px 15px 15px 15px
}
.chat-header {
	position: absolute;
	height: 70px;
	left: 340px;
	right: 0;
	top: 0;
	padding: 15px;
	background: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, .125);
	border-top-right-radius: .25rem;
	z-index: 1
}
.chat-header h4 {
	font-size: var(--fontSize9);
	font-weight: var(--weightNormal);
}
.chat-header .list-inline-item {
	font-size: var(--fontSize7);
	font-weight: var(--weightSmall);
}
.chat-footer {
	position: absolute;
	height: 70px;
	left: 340px;
	right: 0;
	bottom: 0;
	padding: 15px;
	background: #f8f9fa;
	border-top: 1px solid rgba(0, 0, 0, .125);
	border-bottom-right-radius: .25rem
}
.chat-footer-menu-gbl {
	div{
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: var(--fontSize9);
		color: #6c757d;
		text-align: center;
		border-radius: 50%;
		margin: 3px;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.15);
		cursor: pointer;
	}
	div:hover{
		background-color: rgb(240, 240, 240);
	}
	button{
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: var(--fontSize9);
		color: #6c757d;
		text-align: center;
		border-radius: 50%;
		margin: 3px;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.15);
		cursor: pointer;
	}
	button:hover{
		background-color: rgb(240, 240, 240);
	}
	.disabled{
		background-color: rgb(226, 226, 226);
		cursor: default;
	}
	.disabled:hover {
		background-color: rgb(226, 226, 226);
	}
}

.chat-tab-menu li .span-menu.nav-link {
	padding: .3rem .2rem;
	line-height: 1.2;
	color: #4a4b4c;
	cursor: pointer;
	position: relative;
}
.chat-tab-menu li span.nav-link .notifBullet {
    position: absolute;
    background: var(--bs-danger);
    width: 0.9rem;
    padding: 0.2rem 0.14rem 0.14rem;
    border-radius: 100%;
    font-size: var(--fontSize5);
    color: var(--whiteColor);
    font-weight: var(--weightSmall);
    text-align: center;
    top: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.chat-tab-menu li span.nav-link:hover .notifBullet {
	background: var(--blueColor6)
}
.chat-tab-menu .nav-pills .nav-link.active,
.chat-tab-menu .nav-pills .show>.nav-link {
	color: #008cff;
	background-color: rgba(0, 123, 255, 0)
}
.chat-title {
	font-size: var(--fontSize9);
	font-weight: var(--weightNormal);
	color: #272b2f
}
.chat-msg {
	font-size: var(--fontSize8);
	font-weight: var(--weightSmall);
	color: #6c757d
}
.chat-time {
	font-size: var(--fontSize9);
	font-weight: var(--weightNormal);
	color: var(--blackColor);
}
.chat-time span {
	font-size: var(--fontSize7);
	font-weight: var(--weightSmall);
	color: var(--grayColor2);
}
.chat-list {
	position: relative;
	height: 43vh;
	overflow-y: scroll;
}
.chat-list .list-group-item {
	border: 1px solid rgba(0, 0, 0, 0);
	background-color: transparent
}
.chat-list .list-group-item:hover {
	border: 1px solid rgba(0, 0, 0, 0);
	background-color: rgba(13, 110, 253, 0.12)
}
.chat-list .list-group-item.active {
	background-color: rgba(13, 110, 253, 0.12)
}
.chart-online {
	color: #16e15e
}
.chat-top-header-menu div {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: var(--fontSize8);
	font-weight: var(--weightSmall);
	color: #6c757d;
	text-align: center;
	border-radius: 50%;
	margin: 3px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .15);
	cursor: pointer;
}
.chat-content {
	position: relative;
	width: auto;
	height: 65vh;
	overflow-y: scroll;
}
.chat-content-leftside .chat-left-msg {
	width: fit-content;
	background-color: var(--grayColor4);
	font-size: var(--fontSize8);
	font-weight: var(--weightSmall);
	padding: .8rem;
	border-radius: 12px;
	max-width: 480px;
	text-align: left;
	border-top-left-radius: 0
}
.chat-content-rightside .chat-right-msg {
	width: fit-content;
	background-color: var(--blueColor8);
	font-size: var(--fontSize8);
	font-weight: var(--weightSmall);
	padding: .8rem;
	border-radius: 12px;
	float: right;
	max-width: 480px;
	text-align: left;
	border-bottom-right-radius: 0
}
.chat-toggle-btn {
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-right: 15px;
	text-align: center;
	font-size: 24px;
	color: #6c757d;
	border-radius: 50%;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.15)
}
.chat-tab-menu .active small, .chat-tab-menu .active .i {
	color: var(--whiteColor);
}
.chat-tab-menu .nav-link {
	font-size: var(--fontSize8);
}
.chat-wrapper input {
	font-size: var(--fontSize8) !important;
}
.chat-tab-menu .nav-pills .nav-link.active, .chat-tab-menu .nav-pills .show>.nav-link {
    color: var(--whiteColor);
}
.chat-wrapper .dropdown-item {
    color: var(--whiteColor);
    font-size: var(--fontSize7);
}
.chat-wrapper .dropdown-item:hover, .chat-wrapper .dropdown-item:focus, .chat-wrapper .dropdown-item:active {
    color: var(--blackColor);
    background-color: var(--blueColor7);
}
.chat-wrapper .dropdown-toggle {
	cursor: pointer;
}
.chat-wrapper .cursor-pointer::after {
	display: none;
}
.recent-chat {
	border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.recent-chat span {
    font-size: var(--fontSize8);
}

.mobileDisplay {
	background-color: var(--whiteColor);
	border-radius: .25rem;
	padding: 1rem 1rem .1rem;
}