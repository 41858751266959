
@import './globallyStyles.scss';

#homepage-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-homepage{
    background-image: url(../images/bg.png);
    opacity: 1;
    filter: blur(0px);
    box-shadow: rgb(0, 0, 0) 0px 0px 0px inset;
    height: 100vh;
    width: 100%;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.owl-carousel .owl-wrapper:after {
	display: block;
	visibility: hidden;
	clear: both;
	height: 0;
	content: ".";
	line-height: 0;
}

/* display none until init */

.owl-carousel {
	position: relative;
	display: none;
	width: 100%;
	-ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
	position: relative;
	display: none;
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
	float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
	cursor: pointer;
}

.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/* mouse grab icon */

.grabbing {
	// cursor: url(../images/grabbing.png) 8 8, move;
}


/* fix */

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
}

.owl-theme .owl-controls {
	margin-top: 10px;
	text-align: center;
}
/* 
*  Owl Carousel CSS3 Transitions 
*  v1.3.2
*/

.owl-origin {
	-webkit-perspective: 1200px;
	-webkit-perspective-origin-x: 50%;
	-webkit-perspective-origin-y: 50%;
	-moz-perspective: 1200px;
	-moz-perspective-origin-x: 50%;
	-moz-perspective-origin-y: 50%;
	perspective: 1200px;
}


/* fade */

.owl-fade-out {
	z-index: 10;
	-webkit-animation: fadeOut .7s both ease;
	-moz-animation: fadeOut .7s both ease;
	animation: fadeOut .7s both ease;
}

.owl-fade-in {
	-webkit-animation: fadeIn .7s both ease;
	-moz-animation: fadeIn .7s both ease;
	animation: fadeIn .7s both ease;
}


/* backSlide */

.owl-backSlide-out {
	-webkit-animation: backSlideOut 1s both ease;
	-moz-animation: backSlideOut 1s both ease;
	animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
	-webkit-animation: backSlideIn 1s both ease;
	-moz-animation: backSlideIn 1s both ease;
	animation: backSlideIn 1s both ease;
}


/* goDown */

.owl-goDown-out {
	-webkit-animation: scaleToFade .7s ease both;
	-moz-animation: scaleToFade .7s ease both;
	animation: scaleToFade .7s ease both;
}

.owl-goDown-in {
	-webkit-animation: goDown .6s ease both;
	-moz-animation: goDown .6s ease both;
	animation: goDown .6s ease both;
}


/* scaleUp */

.owl-fadeUp-in {
	-webkit-animation: scaleUpFrom .5s ease both;
	-moz-animation: scaleUpFrom .5s ease both;
	animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
	-webkit-animation: scaleUpTo .5s ease both;
	-moz-animation: scaleUpTo .5s ease both;
	animation: scaleUpTo .5s ease both;
}


/* Keyframes */


/*empty*/

@-webkit-keyframes empty {
	0% {
		opacity: 1
	}
}

@-moz-keyframes empty {
	0% {
		opacity: 1
	}
}

@keyframes empty {
	0% {
		opacity: 1
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes backSlideOut {
	25% {
		opacity: .5;
		-webkit-transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(-200%);
	}
}

@-moz-keyframes backSlideOut {
	25% {
		opacity: .5;
		-moz-transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(-200%);
	}
}

@keyframes backSlideOut {
	25% {
		opacity: .5;
		transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		transform: translateZ(-500px) translateX(-200%);
	}
}

@-webkit-keyframes backSlideIn {
	0%,
	25% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		-webkit-transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateZ(0) translateX(0);
	}
}

@-moz-keyframes backSlideIn {
	0%,
	25% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		-moz-transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateZ(0) translateX(0);
	}
}

@keyframes backSlideIn {
	0%,
	25% {
		opacity: .5;
		transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		transform: translateZ(0) translateX(0);
	}
}

@-webkit-keyframes scaleToFade {
	to {
		opacity: 0;
		-webkit-transform: scale(.8);
	}
}

@-moz-keyframes scaleToFade {
	to {
		opacity: 0;
		-moz-transform: scale(.8);
	}
}

@keyframes scaleToFade {
	to {
		opacity: 0;
		transform: scale(.8);
	}
}

@-webkit-keyframes goDown {
	from {
		-webkit-transform: translateY(-100%);
	}
}

@-moz-keyframes goDown {
	from {
		-moz-transform: translateY(-100%);
	}
}

@keyframes goDown {
	from {
		transform: translateY(-100%);
	}
}

@-webkit-keyframes scaleUpFrom {
	from {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}

@-moz-keyframes scaleUpFrom {
	from {
		opacity: 0;
		-moz-transform: scale(1.5);
	}
}

@keyframes scaleUpFrom {
	from {
		opacity: 0;
		transform: scale(1.5);
	}
}

@-webkit-keyframes scaleUpTo {
	to {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}

@-moz-keyframes scaleUpTo {
	to {
		opacity: 0;
		-moz-transform: scale(1.5);
	}
}

@keyframes scaleUpTo {
	to {
		opacity: 0;
		transform: scale(1.5);
	}
}

.bgMenuMobile {
    background-color: var(--darkBlueColor3);
    padding: 0!important;
}
.bgMenuMobile .nav-link {
    padding: 1rem;
}
.ratio-25x9 {
    --bs-aspect-ratio: calc(9 / 25 * 100%);
}
.ratio iframe, .ratio img {
    border-radius: .5rem;
    -moz-border-radius: .5rem;
}


/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
	display: inline-block;
	margin: 5px;
	padding: 3px 10px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #869791;
	color: #FFF;
	font-size: 12px;
	opacity: 0.5;
	filter: Alpha(Opacity=50);
	/*IE7 fix*/
	zoom: 1;
	*display: inline;
	/*IE7 life-saver */
}


/* Clickable class fix problem with hover on touch devices */


/* Use it for non-touch hover action */

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
	text-decoration: none;
	opacity: 1;
	filter: Alpha(Opacity=100);
	/*IE7 fix*/
}


/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
	display: inline-block;
	zoom: 1;
	*display: inline;
	/*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	margin: 5px 7px;
	width: 12px;
	height: 12px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #869791;
	opacity: 0.5;
	filter: Alpha(Opacity=50);
	/*IE7 fix*/
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	opacity: 1;
	filter: Alpha(Opacity=100);
	/*IE7 fix*/
}


/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers {
	padding: 2px 10px;
	width: auto;
	height: auto;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	color: #FFF;
	font-size: 12px;
}


/* preloading images */

.owl-item.loading {
	min-height: 150px;
	// background: url(../images/AjaxLoader.gif) no-repeat center center;
}

/* CUSTOM USER OPTIONS */

.carousel-container {
	width: 100%;
}

.item {
	margin: 5px;
	background: var(--blueColor5);
	color: #333;
	border: 1px solid var(--blueColor1);
	border-radius: .5rem;
	transition: all .10s;
}
.item:hover {	
	box-shadow: rgba(255, 255, 255 , 0.80) 0px 0px 7px 0px;
}

.item img {
	width: 100%;
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
}

.itemContent {
	min-height: 16rem;
    color: var(--whiteColor);
    padding: 1rem;
    position: relative;
}
.item h4 {
	margin-top: 0;
	margin-bottom: 10px;
	color: var(--whiteColor);
	font-family: 'Poppins', sans-serif;
	font-size: var(--fontSize9);
}

.item p {
	color: var(--whiteColor);
	font-family: 'Poppins', serif;
	font-size: var(--fontSize7);
}

.customNavigation {
	margin-top: 0px;
	margin-right: 0px;
	text-align: right;
}

.customNavigation .btn {
	position: relative;
    display: inline-block;
    overflow: visible;
    margin: 0 auto;
    padding: 4px 8px;
    text-align: center;
    box-shadow: none;
    color: #fff;
    text-transform: uppercase;
    text-shadow: none;
    font-size: 10px;
    cursor: pointer;
    border: 0;
    border-radius: 100%;
    -webkit-transition: all .1s ease-in 0s;
    -moz-transition: all .1s ease-in 0s;
    -o-transition: all .1s ease-in 0s;
    transition-property: all .1s ease-in 0s;
    -webkit-font-smoothing: antialiased;
    background: var(--blueColor6);
}

.customNavigation .prev{
    margin-right: 5px;
}

.customNavigation .pplPrev{
    margin-right: 5px;
}


.customNavigation .btn:hover {
	background: var(--blueColor3);
}

.item:hover .figure,
.item:hover .overlay {
	opacity: 1;
}

// -----

.contentWrapper {
	padding-top: 6rem;
    color: var(--primaryColor);
}

.iconEvent {
    max-width: 20rem;
}
.boxEvent {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.imgBoxEvent {
    overflow: hidden;
    margin: 0;
    padding: 0;
    z-index: 9;
}

.iconEvent img {
    max-height: 80px;
    max-width: 14rem;
    min-height: 80px;
    min-width: 80px;
    border-radius: .2rem;
    object-fit: contain;
    background: var(--bgColor);
    padding: 1rem;
}
.titleEvent {
    font-size: var(--fontSize16);
    line-height: 2.1rem;
    letter-spacing: .5px;
    font-weight: var(--weightNormal);
}
.dateEvent {
    font-size: var(--fontSize9);
    color: var(--llColor);
    letter-spacing: -.5px;
    font-weight: var(--weightNormal);
}
.fullDescEvent {
    position:absolute;
    border:1px solid #000;
    top: 0;
    width: 450px;
    padding: 1.5rem;
    display: none;
    background: var(--bgColor);
    animation: 0.5;
    border-radius: 0.5rem;
    box-shadow: 0 0 15px var(--shadowColor);
    -webkit-transform: scale(.99);
    -ms-transform: scale(.99);
    transform: scale(.99);
    animation: 5s;
    z-index: 1;
}
.fullDescEvent:hover {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.descEvent p {    
    font-size: var(--fontSize8);
}

#imgIconEvent #fullDescEvent {
  display: none;
}

#imgIconEvent:hover #fullDescEvent {
  display: block;
}
#more {
    display: none;
}
.imgHeader {
    position: relative;
}
.imgHeader img {
    width: 100%;
    height: auto;
}
.titleHeadOwl {
    color: var(--primaryColor);
    float: left;
}
.titleHeadOwl h2 {
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
    line-height: 2rem;
    letter-spacing: -.3px;
    display: inline;
    margin-left: 0;
}
.titleHeadOwl span {
    background: var(--grayStd);
    font-weight: var(--weightSmall);
    font-size: var(--fontSize6);
    color: var(--whiteColor);
    border-radius: .5rem;
    display: inline;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    line-height: 1.5rem;
    padding: 0 .5rem;
    position: relative;
    top: -.1rem;
    text-transform: capitalize;
    max-width: 9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sessionTimeBookmark {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.sessionTimeBookmark p {
    padding: .3rem .6rem!important;
    border: 1px solid var(--blueColor6);
    color: var(--blueColor7)!important;
    font-size: var(--fontSize7)!important;
    border-radius: .5rem;
}
.itemBookmark {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    .disabled{
        background: var(--blueColor3);
        color: rgb(125, 148, 252);
        cursor: default;
    }
    .disabled:hover{
        background: var(--blueColor3);
        color: rgb(125, 148, 252);
    }
}
.bmBtn {
    right: 1.5rem;
    margin-top: -1.1rem;
    position: absolute;
    color: var(--blueColor7);
    background-color: var(--blueColor5);
    border: 1px solid var(--blueColor6);
    font-size: var(--fontSize7);
    border-radius: 100%;
    min-width: 1.9rem;
    min-height: 1.9rem;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
}
.bmBtn:hover {
    background-color: var(--blueColor6);
    color: var(--whiteColor);
}
.bmBtn.active {
    color: var(--whiteColor);
    background-color: var(--blueColor3);
}
.speakerAvatar {
    position: absolute;
    bottom: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    transition: all .10s;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    transition: .1s all ease-out;
}
.avaSmall {
    margin-right: .2rem;
}
.avaSmall img {
    width: 25px !important;
    height: 25px !important;
    border: 0;
    object-fit: cover;
    border-top-left-radius: 100%!important;
    border-radius: 100%;
}
.avaMore {    
    min-width: 25px;
    padding: 0px 5px;
    height: 25px;
    border: 0;
    object-fit: cover;
    border-top-right-radius: .5rem;
    border-top-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    background: var(--blueColor6);
    transition: .1s all ease-out;
}
.avaMore p {
    text-align: center;
    font-size: var(--fontSize6)!important;
    margin-top: 5px;
    color: var(--blackColor)!important;
    font-size: var(--fontSize6)!important;
    text-decoration: none;
    cursor: pointer;
}
.avaMore a {
    color: var(--whiteColor)!important;
    font-size: var(--fontSize6)!important;
    text-decoration: none;
    cursor: pointer;
}
.avaMore a:hover {
    color: var(--blackColor)!important;
}
.watchSession {
    position: absolute;
    bottom: 1.5rem;
    right: 1.8rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    transition: .1s all ease-out;
    .disabled{
        background: var(--blueColor3);
        color: rgb(125, 148, 252);
        cursor: default;
    }
    .disabled:hover{
        background: var(--blueColor3);
        color: rgb(125, 148, 252);
    }
}
.watchSession span {
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: .3rem .6rem;
    background: var(--blueColor6);
    border-radius: .2rem;
    text-decoration: none;
    cursor: pointer;
}
.watchSession span:hover {
    background: var(--blueColor4);
}
.watchSession .BtnCircle {
    color: var(--blueColor8);
    background-color: var(--blueColor5);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: 100%;
    padding: 7px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
    text-align: center;
    margin-left: .5rem;
}
.watchSession .BtnCircle:hover {
    color: var(--blueColor8);
    background-color: var(--blueColor6);
}
.watchSession .BtnCircle.active {
    color: var(--blueColor8);
    background-color: var(--blueColor3);
}
.watchSession .BtnCircle .i-313123 {    
    width: 12px;
    height: 12px;
}
.moreInfo {
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 100%;
    padding: .2rem;
    cursor: pointer;
    text-align: center;
    transition: .1s all ease-out;
    z-index: -1
}
.itemContent:hover .moreInfo {
    bottom: 0;
    z-index: 1;
}
.itemContent:hover .watchSession, .itemContent:hover .speakerAvatar {
    bottom: 2.5rem;
}
.titleHead {
    color: var(--primaryColor);
    float: left;
}
.titleHead h2 {
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
    line-height: 2rem;
    letter-spacing: -.3px;
    display: inline;
    margin-right: 8px;
}
.titleHead span {
    background: var(--grayStd);
    font-weight: var(--weightSmall);
    font-size: var(--fontSize6);
    color: var(--whiteColor);
    border-radius: .5rem;
    display: inline;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    line-height: 1.5rem;
    padding: 0 .5rem;
    position: relative;
    top: -.1rem;
    text-transform: capitalize;
    max-width: 9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.headContent {
    position: relative;
}
.customViewAll {
    margin-top: 5px;
    position: absolute;
    right: 0;
}

.viewAll {
    color: var(--blueColor6);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    text-decoration: none;
    margin-right: .2rem;
    margin-right: 10px;
    cursor: pointer;
}
.viewAll:hover {
    color: var(--blueColor8);
}
.pollBtn {
    background: var(--blueColor6);
    color: var(--whiteColor);
    font-size: var(--fontSize7);
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
}
.customViewAll span {
    font-size: var(--fontSize7);
    text-decoration: none;
    cursor: default;
}
.borderContent {
    margin: 0 0 30px 0;
    padding: 0;
    background: var(--blueColor5);
    color: #333;
    border: 1px solid var(--blueColor1);
    border-radius: .5rem;
    transition: all .10s;
}
.paddingContent {
    padding: 1rem;
}
.nav-title {
    background-color: var(--blueColor2)!important;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    color: var(--blueColor8);
}
.nav-title:hover {
    color: var(--blueColor1);
}
.nav-item .active {
    background-color: var(--darkBlueColor3)!important;
    color: var(--whiteColor);
}
.schedHome {
    min-height: 23rem;
}
.noUpcome {
    color: var(--blueColor8);
    font-size: var(--fontSize8);
    font-weight: var(--weightNormal);
    position: relative;
    margin-top: 2.5rem;
    letter-spacing: -.03rem;
}
.roomLogo {
    display: flex;
    position: relative;
}
.roomLogo img {
    background: var(--grayStd);
    border: 1px solid var(--grayLight);
    padding: .5rem .7rem;
    border-radius: .5rem;
    cursor: pointer;
}
.roomBottom {
    position: relative;
    height: 2.9rem;
}
.roomBottom p {
    font-size: var(--fontSize7);
    color: var(--secondaryColor);
    margin-bottom: 0;
}
.roomTitle {
    position: relative;
    margin: 1rem 0;
    width: 60%;
    height: 2rem;
}
.roomTitle h3 {
    font-size: var(--fontSize8);
}
.speakerAvatarStd {
    position: absolute;
    top: 1.4rem;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    transition: all .10s;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}
.avaStdSmall {
    margin-right: .2rem;
    margin-top: .5rem;
}
.avaStdSmall img {
    width: 25px;
    height: 25px;
    border: 2px solid var(--whiteColor);
    object-fit: cover;
    border-top-left-radius: 100%!important;
    border-top-right-radius: .5rem;
    border-top-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
}
.avaStdMore {    
    width: 26px;
    height: 26px;
    border: 2px solid var(--whiteColor);
    object-fit: cover;
    border-top-right-radius: .5rem;
    border-top-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    background: var(--blueColor6);
    cursor: pointer;
}
.avaStdMore p {
    text-align: center;
    font-size: var(--fontSize6)!important;
    color: var(--blackColor)!important;
    margin-top: 5px;
}
.bottomRight {
    position: absolute;
    right: 0;
    margin-top: .3rem;
}
.btnRight {
    font-size: var(--fontSize7);
    color: var(--blueColor8);
    background: var(--blueColor2);
    padding: .3rem 1rem;
    text-decoration: none;
    border-radius: .2rem;
}
.btnRight:hover {
    color: var(--blueColor8);
    background: var(--blueColor5);
}

.btnRight i {
    margin-right: .2rem;
}

.imgPeople {
    position: relative;
    width: 100%;
}
.imgPeople img {
    border-top-right-radius: 50%!important;
    border-top-left-radius: .5rem;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 0;
}
@media (min-width: 320px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 115px;
            height: 115px;
        }
        .people-pa {
            object-fit: cover;
            width: 268px;
            height: 268px;
        }
    }
    .custom-card-pp{
        width : 100%;
    }
}
@media (min-width: 375px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 143px;
            height: 143px;
        }
        .people-pa {
            object-fit: cover;
            width: 140px;
            height: 140px;
        }
    }
    .custom-card-pp{
        width : 50%;
    }
}
@media (min-width: 425px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 168px;
            height: 168px;
        }
        .people-pa {
            object-fit: cover;
            width: 140px;
            height: 140px;
        }
    }
    .custom-card-pp{
        width : 50%;
    }
}
@media (min-width: 425px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 168px;
            height: 168px;
        }
        .people-pa {
            object-fit: cover;
            width: 140px;
            height: 140px;
        }
    }
    .custom-card-pp{
        width : 50%;
    }
}
@media (min-width: 576px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 225px;
            height: 225px;
        }
        .people-pa {
            object-fit: cover;
            width: 125px;
            height: 125px;
        }
    }
    .custom-card-pp{
        width : 33.3%;
    }
}
@media (min-width: 600px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 94px;
            height: 94px;
        }
        .people-pa {
            object-fit: cover;
            width: 125px;
            height: 125px;
        }
    }
    .custom-card-pp{
        width : 33.3%;
    }
}
@media (min-width: 768px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 139px;
            height: 139px;
        }
        .people-pa {
            object-fit: cover;
            width: 108px;
            height: 108px;
        }
    }
    .custom-card-pp{
        width : 25%;
    }
}
@media (min-width: 990px) {
    .imgPeople{
        .people-pa {
            object-fit: cover;
            width: 108px;
            height: 108px;
        }
    }
    .custom-card-pp{
        width : 25%;
    }
}
@media (min-width: 991px) {
    .imgPeople{
        .ds-pa {
            object-fit: cover;
            width: 199px;
            height: 199px;
        }
    }
}
@media (min-width: 992px) {
    .imgPeople{
        .people-pa {
            object-fit: cover;
            width: 108px;
            height: 108px;
        }
    }
    .custom-card-pp{
        width : 20%;
    }
}
@media (min-width: 999px) {
    .imgPeople {
        .ds-pa {
            object-fit: cover;
            width: 120px;
            height: 120px;
        }
        .people-pa {
            object-fit: cover;
            width: 108px;
            height: 108px;
        }
    }
    .custom-card-pp{
        width : 20%;
    }
}
@media (min-width: 1200px) {
    .imgPeople {
        .ds-pa {
            object-fit: cover;
            width: 150px;
            height: 150px;
        }
        .people-pa {
            object-fit: cover;
            width: 138px;
            height: 138px;
        }
    }
}
@media (min-width: 1400px) {
    .imgPeople {
        .ds-pa {
            object-fit: cover;
            width: 180px;
            height: 180px;
        }
        .people-pa {
            object-fit: cover;
            width: 168px;
            height: 168px;
        }
    }
}
.imgPeopleBorder {
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 100%;
    padding: 0 1rem 0 0;
}
.descPeople {
    position: relative;
    height: 2.9rem;
    padding: .7rem;
}
.descPeople h4 {
    font-size: var(--fontSize8)!important;
    font-weight: var(--weightNormal);
    color: var(--blueColor8)!important;
    margin-bottom: 0!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.descPeople p {
    font-size: var(--fontSize6)!important;
    font-weight: var(--weightSmall);
    color: var(--blueColor7)!important;
    margin-bottom: 0!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.owl-nav, .owl-dots {
    position: absolute;
    display: none;
}
.boothContent {
    position: relative;
}
.boothContent:hover {
    box-shadow: 0 0 .5rem var(--blueColor8);
    -webkit-box-shadow: 0 0 .5rem var(--blueColor8);
}
.bgBooth {
    position: relative;
    margin-bottom: -.4rem;
}
.bgBooth img {
    width: 100%;
}
.boothContent .BoothOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.boothContent:hover .BoothOverlay {
    display: block;
    background: rgba(0, 0, 0, .4);
    border-radius: .4rem;
}
.absContent {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
}
.topBooth {
    top: 1rem;
    right: 1rem;
    position: absolute;
}
.topBooth .disabled {
    background-color: #273591;
}
.topBooth .disabled:hover {
    background-color: #273591;
}
.botBooth {
    position: absolute;
    bottom: 0rem;
    height: 4rem;
}
.botBooth h3 {
    color: var(--whiteColor);
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    text-decoration: none;
}
.bmBtnCircle {
    color: var(--blueColor8);
    background-color: var(--blueColor5);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: 100%;
    padding: 5px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
}
.bmBtnCircle i {
    width: .6rem;
    height: .6rem;
    text-align: center;
}
.bmBtnCircle:hover {
    color: var(--blueColor8);
    background-color: var(--blueColor6);
}
.bmBtnCircle.active {
    color: var(--blueColor8);
    background-color: var(--blueColor3);
}
.bmBtnBox {
    color: var(--blueColor8);
    background-color: var(--blueColor5);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: .3rem;
    padding: 5px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
    margin-right: .3rem;
}
.bmBtnBox:hover {
    color: var(--blueColor8);
    background-color: var(--blueColor6);
}
.bmBtnBox i {
    margin-right: .2rem;
}
.usrBooth {
    position: absolute;
    top: .8rem;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    transition: all .10s;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}
.avaUsr {
    margin-right: .2rem;
    margin-top: .5rem;
}
.avaUsr img {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid var(--whiteColor);
    object-fit: cover;
    border-top-left-radius: 100% !important;
    border-top-right-radius: .5rem !important;
    border-top-left-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
    border-bottom-left-radius: 100% !important;
    position: relative !important;
}
.usrMore {
    width: 4rem;
}
.usrMore span {
    color: var(--whiteColor) !important;
    font-size: var(--fontSize7);
    text-decoration: none !important;
    cursor: pointer;
}
.widthPopProfile {
    width: 12rem;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 3px;
}
.imgPopHover {
    border-radius: 50%;
    -moz-border-radius: 50%;
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    border: .2rem solid var(--grayStd);
    margin-bottom: 5px;
}
.h3PopHover {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--primaryColor);
    font-size: var(--fontSize9);
    margin-bottom: 0;
}
.pPopHover {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--primaryColor);
    font-size: var(--fontSize7);
}
.btnViewProf {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.btnViewProf span {
    cursor: pointer;
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: .3rem .6rem;
    background: var(--blueColor6);
    border-radius: .2rem;
    text-decoration: none;
    text-align: center;
    width: 100%;
    transition: all .10s;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}
.btnViewProf span:hover {
    background: var(--blueColor4);
}

.popover {
    background-color: var(--blueColor1)!important;
    z-index: 99999;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    border-top-color: var(--grayColor2)!important;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    border-top-color: var(--grayColor2)!important;
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    border-right-color: var(--blueColor1)!important;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: var(--blueColor1)!important;
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: var(--blueColor1)!important;
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    border-bottom: 1px solid var(--blueColor1)!important;
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    border-right-color: var(--blueColor1)!important;
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    border-left-color: var(--blueColor1)!important;
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    border-left-color: var(--blueColor1)!important;
}
.popover-body {
    color: var(--whiteColor)!important;
    background-color: var(--blueColor1)!important;
    border: 1px solid var(--grayColor2)!important;
    border-radius: .2rem!important;
}
.modal-header {
    padding: 0!important;
    border-bottom: 0!important;
    position: relative;
}
.modal-header img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.closeModal {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.modal.fade .modal-dialog{
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
}

.zoom-in {
    transform: scale(0)!important;
    opacity: 0;
    -webkit-transition: .3s all 0s;
    -moz-transition: .3s all 0s;
    -ms-transition: .3s all 0s;
    -o-transition: .3s all 0s;
    transition: .3s all 0s;
    display: block !important;
}

.zoom-in.show {
    opacity: 1;
    transform: scale(1)!important;
    transform:none;
}
.modal-body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: var(--blueColor1);
    border-radius: 1px;
}
.modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: var(--blueColor7);
}

.modal-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--whiteColor);
}
.modal-content {
    background-color: unset;
    padding: 0;
    border-top-left-radius: .5rem!important;
    border-top-right-radius: .5rem!important;
}
.modal-header h5 {
    position: absolute;
}
.btn-close {
    cursor: pointer;
}
.modal-subheader {
    position: relative;
    padding: 1rem 1rem 0; 
    background: var(--blueColor5);
}
.modalBmBtn {
    right: 1rem!important;
}
.modal-lg {
    max-width: 600px;
}
.modal-subheader h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--whiteColor);
    font-family: 'Poppins', sans-serif;
    font-size: var(--fontSize9);
}
.modal-body, .modal-footer, .modal-header {
    background: var(--blueColor5);
}
.modal-header h2 {
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
    line-height: 2rem;
    letter-spacing: -.3px;
    display: inline;
    margin-left: .3rem;
}
.modalNormalize {
    padding: 1rem!important;
    border-bottom: 1px solid var(--blueColor2)!important;
}
.modal-footer {
    border-top: 1px solid var(--blueColor2);
}
.modalContentText {
    color: var(--whiteColor);
    font-size: var(--fontSize7);
    max-height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}
.modalContentText p {
    margin-bottom: 1rem;
    font-weight: var(--weightStandard);
}
.whoIs {
    display: block;
    position: relative;
}
.timeTitle, .whoIsName, .contentAbout {
    font-weight: var(--weightBold);
}
.whoIsJob, .whoIsAs {
    font-style: italic;
    font-weight: var(--weightSmall);
}
.speakerListModal {
    position: relative;
    display: block;
    border-bottom: 1px solid var(--blueColor2);
    padding: .5rem 0;
}
.speakerListModal img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 100%;
    float: left;
    margin-right: 1rem;
}
.listSpeakerDetail {
    position: relative;
    margin-top: .5rem;
}
.listSpeakerDetail h4 {
    margin: 0;
    color: var(--whiteColor);
    font-size: var(--fontSize9);
}
.listSpeakerDetail span {
    color: var(--whiteColor);
    font-size: var(--fontSize7);
}
.speakerListModal .watchSession {
    top: 1.4rem;
    right: 0;
}
.speakerFooter {
    display: block;
    position: relative;
    width: 100%;
    height: 1.6rem;
}
.speakerFooter .watchSession {
    bottom: 0!important;
    right: 0;
}
.speakerFooter .speakerAvatar {
    left: 0!important;
    bottom: 0!important;
    top: .3rem;
}
.speakerFooter .speakerAvatar .avaMore {
    margin-top: -6px;
}

.image-event-session-8713{
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.modalDialogEvent{
    z-index: 9999 !important;
    padding-right: 0px !important;
}

.modalBackdropEvent{
    z-index: 9998 !important;
}

.modalBackdropPerson{
    z-index: 99998 !important;
}

.modalDialogPerson{
    z-index: 99999 !important;
}

.transition-fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.transition-fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 600ms;
}
.transition-fade-exit {
    opacity: 1;
}
.transition-fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 600ms, transform 600ms;
}

.zoom-container-homepage{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 510px) {
    .zoom-container-homepage .titleHead{
        display: flex;
        flex-direction: column;
        span {
            margin-top: 5px;
            max-width: none;
        }
    }
}

@media screen and (max-width: 390px) {
    .zoom-container-homepage{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .customNavigation{
            margin: 0 auto;
            margin-top: 10px;
        }
    }
}

.homeFeed {
    background: #fff;
    padding: 20px;
    border-radius: .5rem;
}
.homeFeedDetail {
    position: relative;
}
.homeFeedDetail p {
    font-size: var(--fontSize9);
    font-weight: var(--weightStandard);
    color: var(--blackColor);
}
.homeFeedAction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;
}
.homeFeedAction span {
    color: var(--whiteColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightNormal);
    padding: 0.5rem 1rem;
    background: var(--blueColor2);
    border-radius: 0.2rem;
    text-decoration: none;
    cursor: pointer;
}
.homeFeedAction span:hover {
    background: var(--blueColor3);
}