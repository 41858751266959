@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');
*, :after, :before {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    vertical-align: baseline;
    text-decoration: none;
}
ol, ul {
    list-style: none;
}
body {
    overflow-x: hidden!important;
    margin: 0 auto;
    padding: 0;
    font-family: "Poppins";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #4954d8;
}

body::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: var(--whiteColor);
}

body::-webkit-scrollbar{
    width: 5px;
    background-color: var(--whiteColor);
}

body::-webkit-scrollbar-thumb
{
    background-color: var(--blueColor1);
    border: 1px solid var(--blueColor1);
}

body::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--blueColor1);
    border: 1px solid var(--blueColor1);
}

.react-toast-notifications__container{
    z-index: 999999999999 !important;
}

@media (min-width: 768px){
    .react-toast-notifications__container{
        margin-top: 4rem;
    }
}