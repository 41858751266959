.overlay-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8849e3+0,4991e3+100&0.5+0,0.5+100 */
    background: -moz-linear-gradient(5deg, rgba(20,20,21,0.5) 0%, rgba(27,28,29,0.7) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(5deg, rgba(20,20,21,0.5) 0%,rgba(27,28,29,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(5deg, rgba(20,20,21,0.5) 0%,rgba(27,28,29,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808849e3', endColorstr='#804991e3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    .overlay-gradient-2 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8849e3+0,4991e3+100&0.5+0,0.5+100 */
    background: -moz-linear-gradient(45deg, rgba(20,20,21,0.1) 0%, rgba(27,28,29,0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(20,20,21,0.1) 0%,rgba(27,28,29,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(20,20,21,0.1) 0%,rgba(27,28,29,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808849e3', endColorstr='#804991e3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    .overlay-gradient-3 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8849e3+0,4991e3+100&0.5+0,0.5+100 */
    background: -moz-linear-gradient(45deg, rgba(127,127,220,0.1) 0%, rgba(29,103,177,0.1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(127,127,220,0.1) 0%,rgba(29,103,177,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(127,127,220,0.1) 0%,rgba(29,103,177,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808849e3', endColorstr='#804991e3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    .pricing-s1 .inner{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8849e3+0,4991e3+100&0.5+0,0.5+100 */
    background: -moz-linear-gradient(45deg, rgba(136,73,227,0.9) 0%, rgba(73,145,227,0.9) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(136,73,227,0.9) 0%,rgba(73,145,227,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(136,73,227,0.9) 0%,rgba(73,145,227,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808849e3', endColorstr='#804991e3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    .g-bg-cyan {
        background: linear-gradient(60deg, #136a8a, #379c94);
        color: #fff !important
    }
    
    .g-bg-blue {
        background: linear-gradient(60deg, #00adef, #0094da);
        color: #fff !important
    }
    
    .g-bg-gy {
        background: linear-gradient(60deg, #94d23b, #fcb711);
        color: #fff !important
    }
    
    .g-bg-cgreen {
        background: linear-gradient(60deg, #16c99f, #12a682);
        color: #fff !important
    }
    
    .g-bg-blush2 {
        background: linear-gradient(325deg, #dd5e89, #f7bb97);
        color: #fff !important
    }
    
    .g-bg-soundcloud {
        background: linear-gradient(60deg, #f83600, #fe8c00);
        color: #fff !important
    }
    
    .g-bg-soundcloud2 {
        background: linear-gradient(135deg, rgba(243, 71, 75, 0.9) 0%, rgba(255, 152, 0, 0.9) 100%);
        color: #fff !important
    }
    
    .l-green {
        background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
    }
    
    .l-pink {
        background: linear-gradient(45deg, pink, #fbc1cb) !important
    }
    
    .l-turquoise {
        background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
        color: #fff !important
    }
    
    .l-khaki {
        background: linear-gradient(45deg, khaki, #fdf181) !important
    }
    
    .l-coral {
        background: linear-gradient(45deg, #f08080, #f58787) !important;
        color: #fff !important
    }
    
    .l-salmon {
        background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
        color: #fff !important
    }
    
    .l-blue {
        background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
        color: #fff !important
    }
    
    .l-seagreen {
        background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
        color: #fff !important
    }
    
    .l-whatsapp {
        background: linear-gradient(45deg, #34b420, #21d811, #4eea36) !important;
        text-align: center;
    }
    
    .l-telegram {
        background: linear-gradient(45deg, #1583b1, #32b2e8, #a3e4ff) !important;
        text-align: center;
    }
    
    .l-gmeet {
        background: linear-gradient(45deg, #76e057, #e6d47b, #f98585, #84c8ff) !important;
        text-align: center;
    }
    
    .l-zoom {
        background: linear-gradient(45deg, #178ae6, #55aaef, #9bd2ff) !important;
        text-align: center;
    }
    .l-amber,
    .chat-launcher,
    .w_calender span+span,
    .w_calender em {
        background: linear-gradient(45deg, #fda582, #f7cf68) !important;
        color: #fff !important
    }
    
    .l-blush {
        background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
        color: #fff !important
    }
    
    .l-parpl {
        background: linear-gradient(45deg, #a890d3, #edbae7) !important;
        color: #fff !important
    }
    
    .l-slategray {
        background: linear-gradient(45deg, #7c8ea0, #dde4ea) !important;
        color: #fff !important
    }
    
    .xl-pink {
        background: #f9edee !important
    }
    
    .xl-turquoise {
        background: #bdf3f5 !important
    }
    
    .xl-khaki {
        background: #f9f1d8 !important
    }
    
    .xl-parpl {
        background: #efebf4 !important
    }
    
    .xl-salmon {
        background: #ffd4c3 !important
    }
    
    .xl-blue {
        background: #e0eff5 !important
    }
    
    .xl-seagreen {
        background: #ebf2e8 !important
    }