@import './globallyStyles.scss';

.topHeader {
    display: block;
    z-index: 99999999;
}
.w100 {
    width: 100%;
}
.pt-7 {
    padding-top: 5rem;
}
.pt-8 {
    padding-top: 6rem;
}
.p10 {
    padding: 0 10px;
}
.p20 {
    padding: 0 20px;
}
.p17 {
    padding: 0 17px;
}
.p15 {
    padding: 0 15px;
}
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}
.cursorHand {
    cursor: pointer;
}

.headerWrapper {
    background: var(--whiteColor);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    height: 4rem;
    z-index: 500;
    position: fixed;
    width: 100%;
}

.zoomMetingHP {
    position: relative;
    text-align: center;
    padding: 0 5px 30px;
    border-bottom: 1px solid var(--blueColor1);
}
.zoomMetingHP p {
    color: var(--whiteColor);
    font-size: var(--fontSize9);
    margin-bottom: 10px;
}
.zoomAction {
    align-items: center;
    transition: .1s all ease-out;
}
.zoomAction span {
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: 0.3rem 0.6rem;
    background: var(--blueColor6);
    border-radius: 0.2rem;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        margin-right: 0.3rem;
    }
}
.zoomAction span:hover {
    background: var(--blueColor4);
}
.zoomActionDisabled span {
    color: rgb(125, 148, 252);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: 0.3rem 0.6rem;
    background: var(--blueColor3);
    border-radius: 0.2rem;
    text-decoration: none;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        margin-right: 0.3rem;
    }
}
.detailAction{
    align-items: center;
    transition: .1s all ease-out;
}
.detailAction span {
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: 0.3rem 0.6rem;
    background: var(--blueColor6);
    border-radius: 0.2rem;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        margin-right: 0.3rem;
    }
}
.detailAction span:hover {
    background: var(--blueColor4);
}
.pollSubmit {
    margin-top: 15px;
    display: block;
}
.pollSubmit .disabled {
    background-color: var(--blueColor3);
    color: var(--blueColor6);
}
.pollSubmit .disabled:hover {
    background-color: var(--blueColor3);
    color: var(--blueColor6);
}
.pollSubmit button {
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: 0.3rem 0.6rem;
    background: var(--blueColor6);
    border-radius: 0.2rem;
    text-decoration: none;
    border: none;
}
.pollSubmit button:hover {
    background: var(--blueColor4);
}
.imageLogo {
    background: var(--darkBlueColor1);
    width: 100%;
}
.imgLogoContent {
    position: relative;
    display: block;
}
.imgLogoContent .image {
    display: inline;
    position: relative;
}
.imgLogoContent img {
    width: auto;
    height: 40px;
    margin-bottom: 15px;
    filter: brightness(0) invert(1);
}
.imgLogoContent ul li {
    padding: 5px 10px;
}
.imgLogoPreveiw .imgLogoContent img {
    height: 30px;
}
.imgCollab {
    margin: .5rem;
}
.itemCollab:hover .desCollab {
    display: block;
}
.imgUsrCollab {
    border-radius: .5rem;
    -moz-border-radius: .5rem;
    padding: 0;
}
.imgUsrCollab img {
    border-radius: .4rem!important;
    -moz-border-radius: .4rem!important;
    height: 100%;
    transition: all .2s;
}
.desCollab {
    position: absolute;
    display: none;
    height: 2.6rem;
    padding: 0 .5rem;
}
.desCollab h4 {
    font-size: var(--fontSize8)!important;
    font-weight: var(--weightNormal);
    color: var(--blueColor8)!important;
    margin-top: 5px!important;
    margin-bottom: 0!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.sesProduct img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.headWatch {
    position: relative;
    background: var(--blueColor5);
    color: #333;
    border: 1px solid var(--blueColor1);
    border-radius: 0.5rem;
    padding: 1rem .5rem ;
}
.headWatch p {
    margin-top: 5px;
    margin-bottom: 5px;
}
.watchZoom {
    margin-left: auto;
    right: 0;
}
@media screen and (max-width: 330px) {
	.imgLogoContent img {
        max-width: 95%;
    }
}

@media screen and (min-width: 760px) {
	.headerWrapper {
        padding: 0 3.3rem;
	}
}

@media screen and (min-width: 769px) {
	.headerWrapper {
        // padding: 0 6.3rem;
	}
}

@media screen and (min-width: 1025px) {
	.headerWrapper {
        transition: 0.5s;
        padding: 0 10.3rem;
	}
}

@media screen and (max-width: 991px) {
    .headImgBooth {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
	.headerWrapper .menuWrapper {
        display: none!important;
	}
    .llLogo {
        margin-left: 1rem;
    }    
    .topHeader {
        display: none!important;
    }
    .pt-8 {
        padding-top: 1rem!important;
    }
    .ratio-21x9 {
        --bs-aspect-ratio: calc(9 / 16 * 100%);
    }
    #sidebar, #show-sidebar {
        display: none;
    }
    .fileIcon {
        display: none;
    }
    .boothFileDetail span {
        position: relative !important;
    }
    .modal-backdrop{
        z-index: 1 !important;
    }
}
.headerWrapper .headerInnerWrapper {
    padding: 0;
}
.headerWrapper .menuWrapper {
    display: block;
}
.menuWrapper .menuInnerWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0px;
}
.menuWrapper .menuInnerWrapper .menuItem {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-width: 4rem;
    max-width: 5rem;
    color: var(--blueColor1);
    cursor: pointer;
    height: 4rem;   
    margin: 0 .5rem;
    position: relative;
    text-align: center;
    color: var(--blueColor4);
}
.menuWrapper .menuInnerWrapper .menuItem a {
    color: var(--blueColor4);
}
.menuWrapper .menuInnerWrapper .menuItem a:hover {
    z-index: 1;
    color: var(--blueColor1);
}
.menuWrapper .menuInnerWrapper .menuItem:before {
    height: .2rem;
    width: 100%;
    background-color: transparent;
    position: absolute;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    bottom: 0;
    content: "";
    color: var(--blueColor7);
}
.menuWrapper .menuInnerWrapper .menuItem .icon {
    position: absolute;
    top: 25%;
    left: 38%;
    height: 1rem;
    width: 1rem;
    font-size: var(--fontSize10);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    z-index: 1;
}
// .menuWrapper .menuInnerWrapper .menuItem .icon:hover {
//     z-index: 1;
//     color: var(--blueColor1);
// }
.menuWrapper .menuInnerWrapper .menuItem .menuText {
    font-weight: var(--weightSmall);
    font-size: var(--fontSize8);
    line-height: 20px;
    text-align: center;
    margin-top: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: -.06px;
    z-index: 2;
}
.pt-6 {
    padding-top: 4rem;
}

.menuWrapper .menuInnerWrapper .menuItem .menuText:hover {
    font-weight: var(--weightNormal);
}

.menuWrapper .menuInnerWrapper .menuItem.active {
    font-weight: var(--weightSmall);
    color: var(--blueColor1);
    z-index: 2;
}

.menuWrapper .menuInnerWrapper .menuItem:hover {
    font-weight: var(--weightNormal);
    color: var(--blueColor1);
    z-index: 2;
}

.menuWrapper .menuInnerWrapper .active .menuText {
    font-weight: var(--weightNormal);
    color: var(--blueColor1);
}
.menuWrapper .menuInnerWrapper .menuItem.active:before {
    background-color: var(--blueColor1);
}
.menuItem .active {
    color: var(--blueColor1);
}
.itemDisable {
    color: #ccc!important;
    cursor: context-menu!important;
}

.rightActionWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.chatIconWrapper.bellIcon {
    margin-right: 1.5rem;
}
.chatIconWrapper {
    position: relative;
    margin-top: 0;
}
.rightActionWrapper .actionIcon .rightIcon {
    width: 1rem;
    height: 2.5rem;
    border-radius: 10rem;
    cursor: pointer;
    display: -webkit-flex;
    color: var(--blueColor4);
    display: flex;
    text-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.rightActionWrapper .actionIcon .rightIcon:hover {
    color: var(--blueColor1);
}

.rightIcon .notifBullet {
    position: absolute;
    background: var(--bs-danger);
    width: 1.2rem;
    padding: .14rem 0;
    border-radius: 100%;
    font-size: var(--fontSize6);
    color: var(--whiteColor);
    font-weight: var(--weightSmall);
    text-align: center;
    top: 0;
    left: 6px;
}

.avtarWrapper {
    position: relative;
    margin-left: 2rem;
}
.avatarWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
}

.avtLayout {
    position: relative;
	width: 3rem;
    height: 2.5rem;
}
.avtLayout img {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    object-fit: cover;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.avtLayout .dropdown-toggle::after {
    display: none;
}

.logout-content-bar{
    width: 250px;
    margin: auto auto;
    .modal-footer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btnActionModal{
            width: 40%;
        }
    }
}