@import './globallyStyles.scss';

#booth-detail-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.headAction {
    position: relative;
}
.boothFrame {
    position: relative;
}
.boothFrame img {
    width: 100%;
    height: auto;
    border-radius: .5rem;
}
.vidPlay {
    display: block;
    text-align: center;
}
.vidPlay span {
    color: var(--whiteColor);
    border: 3px solid var(--whiteColor);
    padding: .5rem;
    border-radius: 100%;
    -moz-border-radius: 100%;
    transition: all .3s ease;
    cursor: pointer;
}
.vidPlay span:hover {
    background-color: var(--blueColor6);
}
.vidPlay i {
    font-size: var(--fontSize8);
    width: 23px;
    height: 23px;
}
.boothFrameImg {
    position: relative;
    padding: 1rem 0;
    background-color: var(--blueColor6);
    border-radius: .5rem;
}
.boothFrameImg img {
    width: 100%;
    height: auto;
}
.pageBoothContent {
    position: relative;
    z-index: 1;
}
.boothFile {
    position: relative;
    padding: .5rem 1rem;
}
.boothFileDetail {
    display: block;
    cursor: default;
}
.fileIcon {
    float: left;
    margin-right: 1rem;
    margin-top: 5px;
}
.fileIcon .fileIcon-1237 {
    font-size: var(--fontSize14);
}
.pdf {
    color: #e63e33;
}
.word {
    color: #7ea6ff;
}
.excel {
    color: #6eb990;
}
.boothFileDetail p, .boothFileDetail span {
    font-size: var(--fontSize8);
    color: var(--whiteColor);
    margin-bottom: 0;
}
.pPoint {
    color: #ce502f;
}
.fImages {
    background: -webkit-linear-gradient(#f57e1f, #ce2123);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fOther {
    color: #9897ff;
}
.boothFileDetail span {
    position: absolute;
    font-size: var(--fontSize6);
    color: var(--blueColor7);
}
.dropup .dropdown-toggle::after {
    display: none !important;
}
.dropup .fileAct {
    border-radius: 100%;
    -moz-border-radius: 100%;
    float: right;
    transition: all .3s ease-out;
    cursor: pointer;
}
.dropup .fileAct:hover {
    background: var(--blueColor6);
}
.dropup i {
    color: var(--whiteColor);
    font-size: var(--fontSize7);
    height: 19px;
    width: 27px;
    text-align: center;
}
.boothFileAction .dropdown-menu span {
    color: var(--blueColor8);
    font-size: var(--fontSize7);
    cursor: pointer;
}

.boothFileAction .dropdown-menu li:hover {
    span{
        color: var(--blueColor7);
    }
}