@import './globallyStyles.scss';

#stage-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contentHeaderDetail {
    border-bottom: 1px solid var(--blueColor7);
}
.subHeadContent {
    position: relative;
    display: flex;
    margin-top: .5rem;
}
.filterRightColumn {
    position: relative;
}
.filterRightColumn p {
    font-weight: var(--weightNormal);
    font-size: var(--fontSize7);
    line-height: 1.4rem;
    color: var(--whiteColor);
    margin: 0;
    padding: 0;
    float: left;
    margin-top: 0.1rem;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    float: left;
    margin-left: .5rem;
    margin-top: 0.1rem;
}
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
.float-left {
    float: left !important;
}
.filterArea {
    margin-left: .5rem;
}
.float-right {
    float: right !important;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--blueColor7);
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: var(--whiteColor);
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: var(--blueColor6);
}
input:focus + .slider {
    box-shadow: 0 0 1px var(--blueColor6);
}
input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    background-color: var(--blueColor7);
    border-radius: 50%;
}
.filterBtn {
    display: block;
    max-width: 7.5rem;
    padding: .3rem .6rem;
    background-color: var(--blueColor7);
    border: 1px solid var(--blueColor7);
    border-radius: .2rem;
    -moz-border-radius: .2rem;
    font-size: var(--fontSize7);
    color: var(--blackColor);
    text-align: center;
    transition: .3s all ease-out;
}

.filterBtn span {
    color: var(--blackColor);
}

.filterBtn:hover {
    background-color: var(--blueColor6);
    border: 1px solid var(--blueColor6);
}

.filterBtn span:hover {
    color: var(--blackColor);
}
.filterBtn i {
    margin-right: .2rem;
}
#wrap {
    display: inline-block;
    position: relative;
    height: 28px;
    float: right;
    padding: 0;
    margin-left: 2.4rem;
    .form-search-stage .disabled{
        background-color: var(--blueColor6);
        cursor: default !important;
    }
}
#wrap input[type="text"] {
    height: 28px;
    font-weight: var(--weightNormal);
    font-size: var(--fontSize7);
    display: inline-block;
    font-weight: 100;
    border: none;
    outline: none;
    color: var(--blackColor);
    padding: 3px;
    padding-right: 28px;
    width: 0px;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    z-index: 3;
    transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
    cursor: pointer;
}
.filterRightColumn #wrap .active {
    width: 300px;
    padding: 0 1rem;
    z-index: 1;
    border-bottom: 1px solid #BBB;
    background-color: var(--blueColor7);
    cursor: text;
    border-radius: 34px;
}
#wrap input[type="text"]:focus:hover {
    border-bottom: 1px solid #BBB;
    background-color: var(--blueColor7);
}
#wrap input[type="text"]:focus {
    width: 300px;
    padding: 0 1rem;
    z-index: 1;
    border-bottom: 1px solid #BBB;
    background-color: var(--blueColor7);
    cursor: text;
    border-radius: 34px;
}
#wrap input[type="submit"] {
    height: 28px;
    width: 30px;
    border-radius: 100%;
    display: inline-block;
    float: right;
    background: url(../images/search.png) center center/12px 12px no-repeat;
    background-color: var(--blueColor7);
    text-indent: -10000px;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    cursor: pointer;
    transition: opacity .4s ease;
}
#wrap input[type="submit"]:hover {
    opacity: 0.8;
}

.dateTopBtn {
    display: block;
    max-width: 7.5rem;
    padding: .3rem .6rem;
    border: 1px solid var(--blueColor7);
    border-radius: .2rem;
    -moz-border-radius: .2rem;
    font-size: var(--fontSize7);
    color: var(--blueColor7);
    text-align: center;
    transition: .3s all ease-out;
}
.dateTopBtn:hover {
    background-color: var(--blueColor7);
    color: var(--blackColor);
}
.dateTopBtn i {
    margin-right: .2rem;
}
.customCalendarBtn {
    position: absolute;
    right: 0;
}
.customCalendarBtn span {
    color: var(--blueColor7);
    border: 1px solid var(--blueColor7);
    border-radius: 100%;
    -moz-border-radius: 100%;
    padding: .2rem .4rem;
    font-size: var(--fontSize9);
    cursor: pointer;
}
.datepicker {
    padding: 1.5rem!important;
    background-color: var(--blueColor5);
    color: var(--whiteColor);
}
.datepicker-dropdown:after {
    border-bottom: 6px solid #413bd8!important;
}
.datepicker table {
    font-family: "Poppins";
}
.datepicker th {
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    width: 30px!important;
    height: 30px!important;
    vertical-align: middle;
}
.datepicker td {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
    width: 30px!important;
    height: 30px!important;
    vertical-align: middle;
    border-radius: 100%!important;
    -moz-border-radius: 100%!important;
}
.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
    background: var(--blueColor7)!important;
    color: var(--blackColor)!important;
    cursor: pointer;
}
.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
    background-color: #413bd8!important;
    background-image: -moz-linear-gradient(to bottom,#2c29bc,#413bd8)!important;
    background-image: -ms-linear-gradient(to bottom,#2c29bc,#413bd8)!important;
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#2c29bc),to(#413bd8))!important;
    background-image: -webkit-linear-gradient(to bottom,#2c29bc,#413bd8)!important;
    background-image: -o-linear-gradient(to bottom,#2c29bc,#413bd8)!important;
    background-image: linear-gradient(to bottom,#2c29bc,#413bd8)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#413bd8', GradientType=0)!important;
    border-color: #2c29bc #413bd8 #fbed50!important;
    color: var(--whiteColor)!important;
}
.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
    background-color: #9897FE!important;
    background-image: -moz-linear-gradient(to bottom,#6d6ce0,#9897FE)!important;
    background-image: -ms-linear-gradient(to bottom,#6d6ce0,#9897FE)!important;
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#6d6ce0),to(#9897FE))!important;
    background-image: -webkit-linear-gradient(to bottom,#6d6ce0,#9897FE)!important;
    background-image: -o-linear-gradient(to bottom,#6d6ce0,#9897FE)!important;
    background-image: linear-gradient(to bottom,#6d6ce0,#9897FE)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6d6ce0', endColorstr='#9897FE', GradientType=0)!important;
    border-color: #9897FE #9897FE #002a80!important;
}
.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
    background-color: var(--blueColor1)!important;
    color: var(--whiteColor)!important;
}
.datepicker .datepicker-switch {
    width: 145px!important;
}
.datepicker table tr td span.focused, .datepicker table tr td span:hover {
    background: var(--blueColor7)!important;
    color: var(--blackColor)!important;
}
.titleStages {
    display: block;
    position: relative;
}
.titleStages h3 {
    font-size: var(--fontSize11);
    line-height: 1.6rem;
    letter-spacing: -.5px;
    color: var(--blueColor7);
}
.titleStages .sessionStart {
    font-weight: var(--weightNormal);
    font-size: var(--fontSize8);
    color: var(--blueColor8);
    line-height: 1rem;
}
.titleStages .sessionStart:after {
    width: calc(100% - 12rem);
    height: 1px;
    background: var(--blueColor8);
    position: absolute;
    content: "";
    right: 0;
    bottom: 6px;
    z-index: -1;
}

.card {
    background-color: var(--blueColor5);
    border-color: var(--blueColor1);
}
.card:hover {
    box-shadow: 0 0 .5rem var(--blueColor8);
    -webkit-box-shadow: 0 0 .5rem var(--blueColor8);
}
.card img {
    height: 100%;
    width: auto;
    object-fit: cover;
}
.card-body {
    padding: 0;
    height: 100%;
}
.itemContent {
    color: var(--whiteColor);
    padding: 1rem;
    position: relative;
    height: 100%;
}
.itemContent h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--whiteColor);
    font-family: 'Poppins', sans-serif;
    font-size: var(--fontSize9);
}
.itemContent p {
    color: var(--whiteColor);
    font-family: 'Poppins', serif;
    font-size: var(--fontSize7);
}
.card-body .itemContent .speakerAvatar {
    bottom: .4rem;
    transition: .1s all ease-out;
}
.card-body .itemContent .speakerAvatar .avaSmall img {
    margin-top: 6px;
}
.card-body .itemContent .sessionTimeBookmark .bmBtn {
    right: 1rem;
}
.card-body .itemContent .watchSession {
    right: 1.2rem;
    bottom: .8rem;
    transition: .1s all ease-out;
}
.card-body .itemContent:hover .watchSession, .card-body .itemContent:hover .speakerAvatar {
    bottom: 2rem;
}
.contentStage {
    position: relative;
    display: block;
    margin-bottom: 2.5rem;
}

.tooltip-custom > .tooltip-inner {
    background-color: var(--blueColor5);
    box-shadow: 0px 0px 1px var(--blackColor);
    opacity: 1 !important;
}

.tooltip-custom .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
    border-top-color: var(--blueColor5) !important;
}

.tooltip-custom .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{
    border-left-color: var(--blueColor5) !important;
}

.tooltip-custom .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: var(--blueColor5) !important;
}

.tooltip-custom .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
    border-right-color: var(--blueColor5) !important;
}