:root {
    --primaryColor: #ffffff;
    --blueColor1: #2d2eb5;
    --blueColor2: #4343d5;
    --blueColor3: #1d1f91;
    --blueColor4: #9291fc;
    --blueColor5: #413bd8;
    --blueColor6: #9897ff;
    --blueColor7: #cbcbff;
    --blueColor8: #e8e8ff;
    --blackColor: #000000;
    --whiteColor: #ffffff;
    --grayColor1: #737373;
    --grayColor2: #999999;
    --grayColor3: #dedede;
    --grayColor4: #f3f3f3;
    --dividerColor: #5d57e6;
    --grayStd: #848eb5;
    --grayLight: #cdd6de;
    --darkBlueColor1: #0b0967;
    --darkBlueColor2: #2c29bc;
    --darkBlueColor3: #08073c; 
	--llColor: #8386f3;
	--llColorHover: #7478e6;
	--buttonColor: #555aea;
	--bgColor: #161973;
	--cardsBGExtraDarkColor: #848f9a;
	--shadowColor: #535e69;
	--cardsBGDarkColor: #66717c;
	--cardsMainDarkColor: #848f9a;
	--hoverColor: #66717c;
	--hoverLightColor: #66717c;
	--lightGraybgColor: #66717c;
	--disabledColor: #525d68;
	--cardsBGColor: #202b36;
	--sidebarBGColor: #202b36;
	--headerBGColor: #202b36;
	--themeDarkColor: #7088a1;
	--themeMainColor: #879fb8;
	--themeLightColorHex: #aac2db;
	--themeLightColor: rgba(135,159,184,0.3);
	--themeExtraLightColor: rgba(135,159,184,0.2);
	--secondaryFontColor: #202b36;
	--secondaryColor: rgba(255,255,255,0.65);
	--secondaryLightColor: rgba(255,255,255,0.4);
	--strokeColor: rgba(255,255,255,0.17);
	--linkColor: #4e60b9;
    --fontSize5: 0.5rem;
    --fontSize6: 0.6rem;
    --fontSize7: 0.7rem;
    --fontSize8: 0.8rem;
    --fontSize9: 0.9rem;
    --fontSize10: 1rem;
    --fontSize12: 1.2rem;
    --fontSize14: 1.4rem;
    --fontSize16: 1.6rem;
    --fontSize18: 1.8rem;
    --fontSize20: 2rem;
    --fontSize22: 2.2rem;
    --fontSize24: 2.4rem;
    --fontSize30: 3rem;
    --fontSize32: 3.2rem;
    --fontSize34: 3.4rem;
    --fontSize36: 3.6rem;
    --fontSize40: 4rem;
    --size10: 1rem;
    --size12: 1.2rem;
    --size14: 1.4rem;
    --size16: 1.6rem;
    --size18: 1.8rem;
    --size20: 2rem;
    --size22: 2.2rem;
    --size24: 2.4rem;
    --size26: 2.6rem;
    --size28: 2.8rem;
    --size30: 3rem;
    --size32: 3.2rem;
    --size34: 3.4rem;
    --size36: 3.6rem;
    --size38: 3.8rem;
    --size40: 4rem;
    --size42: 4.2rem;
    --size44: 4.4rem;
    --size46: 4.6rem;
    --size48: 4.8rem;
    --size50: 5rem;
    --weightSmall: 300;
    --weightStandard: 400;
    --weightNormal: 500;
    --weightMedium: 600;
    --weightBold: 700;
    --weightSemiBold: 800;
    --weightExtraBold: 900;
    --errorColor: #ef574c;
    --errorBgColor: #faddda;
    --successColor: #4cbb3e;
    --successBgColor: #dbf1d8;
    --warningColor: #f4be32;
    --warningBgColor: #fdf2d6;
}