@import './globallyStyles.scss';
@import './emoji.scss';

#forum-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.forum {
    position: relative;
}
.ownerForum {
    background-color: var(--blueColor5);
    padding: 1rem;
    border-radius: .5rem;
    color: var(--whiteColor);
}
.ownerForum img {
    width: 150px;
    height: auto;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.ownerForum h5 {
    font-size: var(--fontSize9);
    font-weight: var(--weightNormal);
}
.ownerForum p {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
}
.headForum {
    background-color: var(--blueColor5);
    padding: 1rem;
    border-radius: .5rem;
    color: var(--whiteColor);
}
.headForum h4 {
    font-size: var(--fontSize10);
    font-weight: var(--weightNormal);
    line-height: 2rem;
    margin-bottom: 0;
    letter-spacing: -.44px;
}
.headForum p {
    font-size: var(--fontSize7);
    color: var(--blueColor7);
    font-weight: var(--weightSmall);
    margin-bottom: 0;
}
.titleHead small {
    font-size: var(--fontSize6) !important;
    font-weight: var(--weightStandard) !important;
}
.boxForum {
    background-color: var(--blueColor5);
    padding: 1rem;
    border-radius: .5rem;
    color: var(--whiteColor);
}
.boxForum img {
    width: 100%;
    height: auto;
    border-radius: .5rem;
    margin-top: .5rem;
}
.boxForum p {
    font-size: var(--fontSize8);
    font-weight: var(--weightNormal);
    margin-bottom: 0;
    letter-spacing: -.05rem;
}
.boxForum .boxForumSpan {
    font-weight: var(--weightSmall);
    color: var(--blueColor7);
    letter-spacing: -.02rem;
    line-height: .8rem;
}
.boxForum span {
    color: var(--whiteColor);
}
.boxForum .customViewAll {
    margin-top: 0;
}
.boxForum .btnLight {
    margin-right: 0;
    color: var(--blackColor);
}
.boxForum .headTopBtn {
    margin-right: .3rem;
}
.ui-autocomplete {
    width: 300px !important;
}
.ui-menu-item {
    display: block;
    padding: .5rem 1rem;
    background-color: var(--blueColor1);
    border-bottom: 1px solid var(--blueColor2);
}
.ui-menu-item .ui-corner-all {
    color: var(--whiteColor);
    font-size: var(--fontSize8);
}
.ui-menu-item a {
    cursor: pointer;
}
.boxPost {
    position: relative;
}
.postHead {
    padding: 1rem;
    background-color: var(--whiteColor);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.postHead a {
    color: var(--blackColor);
}
.profPic {
    height: 45px;
}
.profPic img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 100%;
    -moz-border-radius: 100%;
    margin-right: 1rem;
}
.profName {
    display: block;
}
.profName span {
    color: var(--blackColor);
    font-size: var(--fontSize9);
    font-weight: var(--weightNormal);
}
.profName p {
    color: var(--grayColor1);
    font-size: var(--fontSize8);
    font-weight: var(--weightSmall);
    margin-bottom: 0;
}
.postContent {
    position: relative;
    padding: 0 1rem 0.5rem 1rem;
    background-color: var(--whiteColor);
}
.postContent textarea {
    color: var(--blackColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    width: 100%;
    background: rgba($color: #000000, $alpha: 0);
    resize: none;
}
.postTool {
    position: relative;
    background-color: var(--whiteColor);
    border-top: 1px solid var(--blueColor8);
}
.postFooter {
    width: 100%;
    position: relative;
    background-color: var(--whiteColor);
    padding: 1rem;
    border-top: 1px solid var(--blueColor8);
    .input-group{
        width: 100%;
    }
}
.reactLike, .reactLove, .reactLaugh, .reactHuge, .reactCrying, .reactAngry, .reactSurprise {
    position: relative;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    top: .1rem;
    margin-right: -.3rem;
}
.reactLike {
    background-image: url(../images/react/like.png);
}
.reactLove {
    background-image: url(../images/react/love.png);
}
.reactLaugh {
    background-image: url(../images/react/laugh.png);
}
.reactHuge {
    background-image: url(../images/react/huge.png);
}
.reactCrying {
    background-image: url(../images/react/crying.png);
}
.reactAngry {
    background-image: url(../images/react/angry.png);
}
.reactSurprise {
    background-image: url(../images/react/surprise.png);
}
.reactWho {
    color: var(--grayColor1);
    font-size: var(--fontSize8);
    font-weight: var(--weightSmall);
    margin-left: .7rem;
}
.reactAct, .commentSum {
    position: relative;
}
.commentSum span {
    color: var(--grayColor1);
    font-size: var(--fontSize8);
    font-weight: var(--weightSmall);
}
.postToolAction {
    padding-bottom: .5rem;
    border-top: 1px solid var(--blueColor8);
    border-bottom: 1px solid var(--blueColor8);
}
.postToolAction .nav-item {
    padding-top: .5rem;
}
.postToolAction .nav-link .i {
    margin-right: .2rem;
}
.postToolAction .nav-link {
    color: var(--grayColor1);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
}
.postToolAction .nav-link:hover {
    color: var(--blackColor) !important;
    background: var(--blueColor8);
}
.columnComment {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    position: relative;
    padding: 1rem;
    background-color: var(--whiteColor);
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
}
.columnComment h5 {
    color: var(--blackColor);
    font-size: var(--fontSize9);
    font-weight: var(--weightNormal);
    margin-bottom: 0;
}
.columnComment p {
    padding: 5px 0px;
    width: 100%;
    resize: none;
    background-color: rgba($color: #000000, $alpha: 0);
    color: var(--blackColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightSmall);
    margin-bottom: 0;
}
.columnComment span {
    color: var(--grayColor2);
    font-size: var(--fontSize7);
    cursor: pointer;
}
.columnComment span:hover {
    color: var(--grayColor1);
    text-decoration: underline;
}
.otherComment {
    position: relative;
}
.otherComment span {
    color: var(--grayColor2);
    font-size: var(--fontSize9);
    font-weight: var(--weightNormal);
    cursor: pointer;
}
.otherComment span:hover {
    color: var(--grayColor1);
}
.postFooter input, .boxForum textarea {
    color: var(--blackColor) !important;
    font-size: var(--fontSize8) !important;
    font-weight: var(--weightSmall) !important;
}
.chat-footers {
    position: relative;
    right: 0;
    bottom: 0;
}
.chat-footers-menu div {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    border-radius: 50%;
    margin: 3px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.postImages {
    position: relative;
}
.postImages img {
    width: 100%;
    height: auto;
    margin-bottom: -7px;
}
.pollAnswer .form-check .form-check-label {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
}
.form-check-input:checked {
    background-color: var(--llColor);
    border-color: var(--llColor);
}
.forumAds {
    position: relative;
}
.forumAds img {
    width: 100%;
    height: auto;
    border-radius: .5rem;
    -moz-border-radius: .5rem;
}
.dropFooterMsg {
    border-top: 1px solid var(--dividerColor);
    padding: .5rem;
    cursor: pointer;
}
.dropFooterMsg.disable{
    cursor: not-allowed;
}
.dropFooterMsg span {
    color: var(--whiteColor);
    font-size: var(--fontSize8);
}
.profileImg {
    padding: 0;
    border-radius: .5rem;
    color: var(--whiteColor);
}
.profileImg img {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.profileImg .nav {
    display: block;
    flex-wrap: unset;
}
.col-form-label {
    font-size: var(--fontSize8);
    line-height: 1.5;
}
.userUpdate .dropdown-item {
    color: var(--whiteColor);
}
.userUpdate .dropdown-item:hover {
    background-color: var(--blueColor6);
    color: #212529;
}
.userUpdate .dropdown-item:active {
    color: #212529;
}
.userUpdate .dropdown-item .text-primary {
    color: var(--blueColor8) !important;
}

.link-13124{
    cursor: pointer;
}

.link-13124:hover{
    text-decoration: underline;
}

.mention-reply{
    width: calc(100% - 10px);
    input {
        margin-top: 3px !important;
        border: 1px solid #ced4da;
        width: 100%;
        resize: none;
        font-size: 12px !important;
        padding: 5px 10px;
        border-radius: 4px;
    }
}

.mention-content{
    width: calc(100% - 41px);
    .mention-content__highlighter{
        display: none;
    }
    input {
        border: 1px solid #ced4da;
        width: 100%;
        resize: none;
        font-size: 12px !important;
        padding: 5px 10px;
    }
    .mention-content__control{
        input{
            width: 100%;
            background-color: red;
        }
    }
}

.mention-textarea{
    .mention-textarea__highlighter{
        display: none;
    }
    textarea {
        border: 1px solid #ced4da;
        width: 100%;
        border-radius: .25rem;
        padding: .375rem .75rem;
        min-height: 100px;
        resize: none;
    }
    .mention-textarea__control{
        input{
            width: 100%;
            background-color: red;
        }
    }
}

.mention-reply__suggestions{
    width: 200px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 4px;
    .mention-reply__suggestions__list{
        .mention-reply__suggestions__item{
            padding: 5px 15px;
            border-bottom: 1px solid rgb(231, 231, 231);
            div{
                display: flex;
                align-items: center;
                text-align: center;
                column-gap: 7px;
                img{
                    margin: 0px;
                    height: 20px;
                    width: 20px;
                }
                span{
                    font-size: 12px;
                    color: black;
                }
            }
        }
        .mention-reply__suggestions__item--focused{
            background-color: #737ef1;
            div{
                span{
                    color: white;
                }
            }
        }
    }
}

.mention-content__suggestions{
    width: 200px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 4px;
    .mention-content__suggestions__list{
        .mention-content__suggestions__item{
            padding: 5px 15px;
            border-bottom: 1px solid rgb(231, 231, 231);
            div{
                display: flex;
                align-items: center;
                text-align: center;
                column-gap: 7px;
                img{
                    margin: 0px;
                    height: 20px;
                    width: 20px;
                }
                span{
                    font-size: 12px;
                    color: black;
                }
            }
        }
        .mention-content__suggestions__item--focused{
            background-color: #737ef1;
            div{
                span{
                    color: white;
                }
            }
        }
    }
}

.mention-textarea__suggestions{
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 4px;
    .mention-textarea__suggestions__list{
        .mention-textarea__suggestions__item{
            padding: 5px 15px;
            border-bottom: 1px solid rgb(231, 231, 231);
            div{
                display: flex;
                align-items: center;
                text-align: center;
                column-gap: 7px;
                img{
                    margin: 0px;
                    height: 20px;
                    width: 20px;
                }
                span{
                    font-size: 12px;
                    color: black;
                }
            }
        }
        .mention-textarea__suggestions__item--focused{
            background-color: #737ef1;
            div{
                span{
                    color: white;
                }
            }
        }
    }
}

.hashtag{
    font-size: 12px;
    word-spacing: -2px;
    color: white;
    background-color: #737ef1;
    width: fit-content;
    padding: 2px 10px;
    border-radius: 4px;
}

.hashtag::before{
    content: '#';
    font-size: 12px;
}

.option-forum{
    padding: 0px;
    margin: 0px;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0px;
    color: black;
    outline: none;
    box-shadow: none !important;
}

.option-forum:hover{
    padding: 0px;
    margin: 0px;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0px;
    color: black;
}

.option-forum:focus{
    padding: 0px;
    margin: 0px;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0px;
    color: black;
}

.option-menu-forum{
    a {
        color: white;
    }
}

@media (max-width: 991px) {
    .forumAds {
        display: none;
    }
}