.ourBlog {
    position: relative;
}
.ourBlog img {
    width: 100%;
    height: 227px;
    object-fit: cover;
}
.itemBlog {
    min-height: auto;
    color: var(--whiteColor);
    padding: 1rem;
}
.itemBlog p {
    color: var(--whiteColor);
    font-family: 'Poppins', serif;
    font-size: var(--fontSize7);
    margin-bottom: 0;
}
.itemBlog h4 {
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.itemTimeBlog {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
}
.itemTimeBlog p {
    position: absolute;
    background-image: linear-gradient(127deg, #555aea 0%, #1d1f91 91%);
    margin-top: -45px;
    padding: 0.3rem 0.6rem!important;
    border: 1px solid var(--blueColor6);
    color: var(--blueColor7)!important;
    font-size: var(--fontSize7)!important;
    border-radius: 0.5rem;
}