@import './homepage.scss';

a {
    text-decoration: none;
}
a:hover {
	text-decoration: underline;
}
.website-logo {
    display: inline-block;
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
    position: absolute;
    z-index: 1000;
}
.website-logo a {
    display: inline-block;
}
.website-logo .logo {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
}
.website-logo .logo img.logo-size {
    width: 100px;
}
.event-login {
	position: relative;
    margin: 0 auto;
    transition: 0.5s;
}
.img-holder {
    width: 50%;
	display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 400px;
    height: 100%;
    overflow: hidden;
    text-align: center;
    z-index: 999;
    background-color: var(--whiteColor);
    background-repeat: no-repeat;
    background-size: cover;
}
.img-holder .bg {
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}
.img-holder .info-holder {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 60px;
}
.img-holder .info-holder img {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.37);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.37);
}
.form-holder {
    margin-left: 50%;
	width: 50%;
    height: 100%;
    transition: 0.5s;
}
.form-holder .form-content {
    position: relative;
    display: flex!important;
    height: 100vh;
    width: 100%;
    text-align: center;
    align-items: center;
	background-color: var(--blueColor5);
}
.form-content .form-items {
    max-width: 350px;
    text-align: left;
    display: inline-block;
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.form-content h2 {
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
    text-align: left;
    line-height: 1rem;
    letter-spacing: -.3px;
    display: inline;
    margin-bottom: .9rem;
}
.form-content p {
    color: var(--whiteColor);
    text-align: left;
    font-size: var(--fontSize7);
    font-weight: var(--weightStandard);
    line-height: .9rem;
    margin-bottom: 1.5rem;
}
.form-content .page-links {
    margin-bottom: 34px;
}
.form-content .page-links span {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: var(--whiteColor);
    font-weight: var(--weightStandard);
    font-size: var(--fontSize9);
    margin-right: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}
.form-content .page-links span:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -10px;
    background-color: var(--whiteColor);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.form-content .page-links span.active {
    font-weight: var(--weightBold);
}
.form-content .page-links span.active:after {
    background-color: var(--llColor);
}
.form-content .page-links span:after {
    background-color: var(--whiteColor);
}
.form-content .page-links span:last-child {
    margin-right: 0;
}
.form-content .page-links span:hover:after, .form-content .page-links span:focus:after {
    background-color: var(--llColor);
}
.form-content form {
    margin-bottom: 20px;
}
.form-content input, .form-content .dropdown-toggle.btn-default {
    width: 100%;
    padding: 9px 20px;
    text-align: left;
    border: 0;
    outline: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 149, 255, 0);
    background-color: var(--blueColor8);
    color: var(--blackColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
}
.form-content .form-button {
    margin-top: 10px;
    margin-bottom: 10px;
}
.form-content .form-button .disabled {
    font-size: 13px;
    color: var(--grayStd);
}
.form-content .form-button .disabled:hover {
    font-size: 13px;
    color: var(--grayStd);
    text-decoration: none;
    cursor: default;
}
.form-content .form-button .ibtn {
    border-radius: 6px;
    border: 0;
    padding: 6px 28px;
    background-color: var(--llColor);
    color: var(--whiteColor);
    font-size: 14px;
    font-weight: var(--weightBold);
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 0 0 rgba(80, 182, 255, 0.31);
    box-shadow: 0 0 0 rgba(80, 182, 255, 0.31);
}
.form-content .form-button .ibtn:hover, .form-content .form-button .ibtn:focus {
    -webkit-box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
    box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
    text-decoration: none;
}
.form-content .form-button span {
    font-size: 13px;
    font-weight: var(--weightBold);
    color: var(--whiteColor);
    cursor: pointer;
}

.form-content .form-button span:hover {
    text-decoration: underline;
}

.form-content .other-links span {
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    color: var(--whiteColor);
    margin-right: 20px;
}
.form-content .other-links a {
    font-size: var(--fontSize8);
    font-weight: var(--weightBold);
    color: var(--whiteColor);
    margin-right: 10px;
}
.form-content .other-links a:hover {
	text-decoration: underline;
}
#qr-reader a {
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    color: var(--whiteColor);
}
#qr-reader > div:first-child {
    display: none;
}
#qr-reader #qr-reader__header_message{
    border-top: 0px !important;
}

#qr-reader__dashboard_section_csr button {
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    color: var(--whiteColor);
    background-color: var(--llColor);
    border: 0;
    padding: .5rem;
}
#qr-reader__status_span {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
    color: var(--blackColor);
}
#qr-reader__dashboard_section_csr span, #qr-reader__dashboard_section_fsr span {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
    color: var(--whiteColor);
}
#qr-reader__camera_selection {
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
    color: var(--blackColor);
}
.alert {
    padding: .5rem;
    font-size: var(--fontSize7);
    font-weight: var(--weightSmall);
}
.alert-dismissible .btn-close {
    padding: .7rem;
}
.btn-close:focus {
    outline: 0;
    box-shadow: none;
}
@media screen and (max-width: 767px) {
    .website-logo {
        display: none;
    }
	.img-holder {
		display: none;
	}
	.form-holder {
		margin-left: 0!important;
		width: 100%!important;
	}
	.form-holder .form-content {
		padding: 40px;
	}
}

@keyframes InputAnimation {
    0% {
        opacity: 0;
        width: 0%;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

.animation-input-lgn{
    animation: InputAnimation 0.5s ease-in-out;
}

.option-section-3871 {
    font-size: 13px;
    color: var(--whiteColor);
}

.option-section-3871 .disabled {
    font-size: 13px;
    color: var(--grayStd);
}
.option-section-3871 .disabled:hover {
    font-size: 13px;
    color: var(--grayStd);
    text-decoration: none;
    cursor: default;
}

.option-section-3871 span {
    font-weight: var(--weightBold);
    cursor: pointer;
}

.option-section-3871 span:hover {
    text-decoration: underline;
}

.regist-timeM{
    display: none;
}

@media screen and (max-width: 375px) {
    .regist-timeM{
        display: block;
    }
    .regist-timeD{
        display: none;
    }
}

.selectable-form-control{
    background-color: #e8e8fe;
    width: 100%;
    padding: 6.5px 16px;
    border: 0;
    outline: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 149, 255, 0);
    background-color: var(--blueColor8);
    color: var(--blackColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.selectable-form-control:hover{
    background-color: white;
    color: var(--blackColor);
}

.selectable-form-control:focus{
    background-color: white;
    color: var(--blackColor);
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle{
    background-color: white;
    color: var(--blackColor);
}

.selectable-form-dropdown-flag .dropdown-menu{
    min-width: 100px;
}

.selectable-form-control-item{
    color: white;
    text-decoration: none;
    width: 100px;
}

.selectable-form-control-item:hover{
    text-decoration: none;
}

.selectable-form-control-body input{
    height: 30px;
    margin-bottom: 3px;
}

.selectable-form-control-content {
    max-height:200px;
    overflow-y: auto;
    overflow-x: hidden;
    text-decoration: none !important;
}

.form-phone-input .params-phone-code{
    width: 60px;
    padding: 0px 0px;
    border: 1px solid rgba(0, 149, 255, 0);
    border-right: 0px;
    text-align: left;
    border-radius: 6px 0px 0px 6px;
    background-color: var(--blueColor8);
    color: var(--blackColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
    line-height: 1.5;
    text-align: center;
}

.form-phone-input .params-phone-input{
    border-radius: 0px 6px 6px 0px;
    border-left: 0px;
    padding-left: 5px;
}

.form-phone-input .params-phone-input:focus{
    border-left: 0px !important;
}

.form-firstlastname-wrap{
    .form-firstname {
        padding-right: 5px;
    }
    .form-lastname {
        padding-left: 5px;
    }
}

.spinner-loading-form{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    margin-top: 7px;
    margin-right: 7px;
}

@media (max-width: 767px){
    .form-firstlastname-wrap{
        .form-firstname {
            padding-right: calc(var(--bs-gutter-x) * .5);
        }
        .form-lastname {
            padding-left: calc(var(--bs-gutter-x) * .5);
        }
    }
}

#qr-canvas-visible{
    height: 150px !important;
    width: 150px !important;
    padding-top: 10px;
}