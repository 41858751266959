@import './globallyStyles.scss';

#booth-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.boothContent img {
    height: 12rem;
    object-fit: cover;
    border-radius: .4rem;
    position: relative;
}
.botBoothRight {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 1px solid var(--grayColor2);
    border-radius: .3rem;
    background: var(--blueColor5);
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    z-index: 99;
}
.botBoothRight img {
    width: auto;
    height: 80px;
    margin: 0 auto;
    border-radius: .3rem;
}
.formInput {
    position: relative;
    display: block;
}
select,
select.form-control,
textarea, 
textarea.form-control, 
input.form-control, 
input[type=text], 
input[type=password], 
input[type=email], 
input[type=number], 
[type=text].form-control, 
[type=password].form-control, 
[type=email].form-control, 
[type=tel].form-control, 
[contenteditable].form-control {
    font-size: var(--fontSize7);
}
select.focus,
select.form-control:focus,
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
    box-shadow: none;
    outline: 0;
    border: 1px solid var(--grayColor2);
}
.modalAction {
    position: relative;
    bottom: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    transition: .1s all ease-out;
}
.btnActionModal {
    color: var(--blackColor);
    font-size: var(--fontSize7);
    font-weight: var(--weightNormal);
    padding: .3rem .6rem;
    background: var(--blueColor6);
    border-radius: .2rem;
    text-decoration: none;
    margin-left: .5rem;
}
.btnActionModal:hover {
    background: var(--blueColor4);
}
.btnActionModal i {
    margin-right: .2rem;
}
.modalActLeft {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}
.btnActionCircle {
    color: var(--blueColor8);
    background-color: var(--blueColor5);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: 100%;
    padding: 5px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
}
.btnActionCircle:hover {
    color: var(--blackColor);
    background-color: var(--blueColor6);
}
#chatForm {
    bottom: 0;
    right: .5rem;
    z-index: -1;
    position: fixed;
}
.toast.show {
    z-index: 9999 !important;
}
.toast-header {
    background: var(--blueColor8);
}
.toast-header img {
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.toast-header h5 {
    color: var(--blackColor);
    font-size: var(--fontSize8);
}
.toast-body {
    min-height: 20rem;
    max-height: 20rem;
    background: var(--blueColor5);
    overflow-y: auto;
}

.toast-body .date-chats{
    color: var(--grayColor3);
    font-size: .7rem;
    display: flex;
    flex-direction: row;
    padding: 0.7rem 0px;
}

.toast-body .date-chats:before,
.toast-body .date-chats:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
    opacity: 0.5;
}

.toast-body .date-chats:before {
    margin-right: 10px
}
.toast-body .date-chats:after {
    margin-left: 10px
}

.toast-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: var(--whiteColor);
}

.toast-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--whiteColor);
}

.toast-body::-webkit-scrollbar-thumb {
    background-color: var(--blueColor1);
    border: 1px solid var(--blueColor1);
}

.toast-body::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--blueColor1);
    border: 1px solid var(--blueColor1);
}

.chatUserAvatar {
    float: left;
    height: 2rem;
    width: 2rem;
}
.chatUserAvatar img {
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.chatMessage {
    list-style: outside none none;
    padding: 0;
    position: relative;
}
ul.chatMessage {
    float: left;
    margin: 0 0 0 20px !important;
    max-width: 77%;
}
ul.chatMessage li {
    background-color: var(--blueColor8);
    padding: .5rem;
    margin-bottom: .3rem;
    font-size: var(--fontSize7);
    border-radius: .5rem;
}
ul.chatMessageFirst li:first-child::before {
    border-right-color: var(--blueColor8);
    border-color: transparent var(--blueColor8) transparent transparent;
    border-style: solid;
    border-width: 0 19px 19px 0;
    content: "";
    height: 0;
    left: -12px;
    position: absolute;
    top: 0;
    width: 0;
}
ul.chatMessage p {
    margin-bottom: 0;
}
ul.chatMessage h6 {
    font-size: var(--fontSize8);
}
.chatMessageTime {
    color: var(--grayColor2);
    display: block;
    font-size: var(--fontSize6);
    padding-top: 2px;
}
.chatMessageWrapper {
    position: relative;
    display: table;
}
.chatMessageWrapper::after, .chatMessageWrapper::before {
    content: " ";
    display: table;
}
.chatMessageWrapper.chatRight{
    width: 100%;
    margin-left: auto;
}
.chatMessageWrapper.chatRight .chatUserAvatar {
    float: right;
}
.chatMessageWrapper.chatRight ul {
    float: right;
    right: 12px;
}
.chatMessageWrapper.chatRight ul.chatMessage li {
    background-color: var(--blueColor7);
    padding: .5rem;
    font-size: var(--fontSize7);
    border-radius: .5rem;
}
.chatMessageWrapper.chatRight ul.chatMessage > li:first-child::before {
    border-left-color: var(--blueColor7);
    border-color: transparent transparent transparent var(--blueColor7);
    border-width: 0 0 19px 19px;
    left: auto;
    right: -12px;
}
.toast-footer {
    padding: .5rem 1rem;
    background: var(--blueColor5);
    border-top: 1px solid var(--grayColor2);
}
.sendMessage {
    position: relative;
    bottom: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    transition: .1s all ease-out;
}
.sendMessage .BtnCircle {
    color: var(--blueColor8);
    background-color: var(--blueColor5);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: 100%;
    padding: 6px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
    text-align: center;
    margin-left: .5rem;
}
.sendMessage .BtnCircle:hover {
    color: var(--blueColor8);
    background-color: var(--blueColor6);
}
.me-auto a {
    color: var(--blackColor);
}
.me-auto a:hover {
    color: var(--grayColor2);
}

.transition-enter {
    opacity: 0;
    transform: translate(0px, 100px);
}
.transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.transition-exit {
    opacity: 1;
}
.transition-exit-active {
    opacity: 0;
    transform: translate(100px, 0px);
    transition: opacity 300ms, transform 300ms;
}

.modalBackdropNote{
    z-index: 999998 !important;
}

.modalDialogNote{
    z-index: 999999 !important;
}