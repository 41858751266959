@import './globallyStyles.scss';

#edit-profile-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#userUpdateList .textTabs {
    display: inline-block;
}
#userUpdateList .iconTabs {
    display: inline-block;
    line-height: 2rem!important;
    margin-right: .5rem;
}
#userUpdateList .profTab {
    background-color: var(--blueColor2);
    border-radius: .5rem;
}
#userUpdateList .profTab .nav-link:first-child {
    border-bottom-left-radius: .5rem;
    border-top-left-radius: .5rem;
}
#userUpdateList .profTab .nav-link:last-child {
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
}
#userUpdateList .profTab .nav-link.active {
    background-color: var(--blueColor1) !important;
}
.boxForumTabs {
    background-color: var(--blueColor5);
    border-radius: .5rem;
    color: var(--whiteColor);
}

.intl-tel-input{
    width: 100%;
    .flag-container .arrow{
        color: #212529 !important;
        font-size: 9px !important;
    }
}

.flag-container .country-list .country{
    .country-name{
        font-weight: var(--weightSmall);
        color: #212529 !important;
        letter-spacing: .02rem;
        line-height: .8rem;
        margin-right: 6px;
        font-size: 11px;
    }
    .dial-code{
        font-weight: var(--weightSmall);
        color: #212529 !important;
        letter-spacing: .02rem;
        line-height: .8rem;
        margin-right: 6px;
        font-size: 11px;
    }
}

#basic-typeahead-multiple a {
    text-decoration: none;
}

.from-group #about{
    font-size: var(--fontSize7) !important;
}

#filename-photopicture{
    display: none;
}

.ownerLink .disabled{
    background-color: var(--bgColor);
    color: var(--buttonColor) !important;
    border-color: var(--buttonColor) !important;
}

.intl-tel-input .flag-container .selected-flag .selected-dial-code{
    padding-left: 8px;
    font-size: var(--fontSize7);
    color: #212529;
}


.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
    display: flex;
    justify-content: center;
    align-items: center;
}