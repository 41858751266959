@import './globallyStyles.scss';

.people-87612{
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.speakerTabs {
    position: relative;
}
.speakerTabs .nav-item {
    display: block;
    position: relative;
}
.speakerTabs .nav-item .active:after {
    content: "";
    background-color: var(--blueColor6);
    height: .2rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.speakerTabs .nav-item .nav-link {
    padding: .62rem .5rem;
}
.contentPeople {
    position: relative;
    display: block;
    padding: 2rem 0;
}
.contentPeopleSort {
    position: relative;
    display: block;
    padding: 2rem 0 0;
}
.PeopleSort {
    padding-bottom: 1.5rem;
}
.PeopleSort2nd {
    display: block;
    padding-bottom: 0;
}
.btnLight {
    color: var(--blackColor);
    background-color: var(--blueColor7);
    border: 1px solid var(--grayColor2);
    font-size: var(--fontSize7);
    border-radius: .3rem;
    padding: 5px 9px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;
    margin-right: .3rem;
}
.btnLight.disable {
    background-color: var(--blueColor6);
    color: var(--blackColor);
    cursor: not-allowed;
}
.btnLight:hover {
    background-color: var(--blueColor6);
    color: var(--blackColor);
}
.btnLight .i {
    margin-right: .2rem;
}
.sortMenu .dropdown-item {
    color: var(--whiteColor);
    font-size: var(--fontSize7);
    cursor: pointer;
}
.sortMenu .dropdown-item:hover, .sortMenu .dropdown-item:focus, .sortMenu .dropdown-item:active {
    color: var(--blackColor);
    background-color: var(--blueColor7);
}
.suggestPeople {
    position: relative;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--dividerColor);
}
.aboutProfile {
    position: relative;
    padding: 1rem;
    border-top: 1px solid var(--dividerColor);
}
.aboutProfile h5 {
    color: var(--whiteColor);
    font-size: var(--fontSize9);
    font-weight: var(--weightNormal);
    margin-bottom: .5rem;
}
.aboutProfile p {
    color: var(--whiteColor);
    font-size: var(--fontSize8);
    font-weight: var(--weightStandard);
    margin-bottom: .5rem;
}
.actionIcon .dropdown .dropdown-toggle::after, .MessIcon .dropdown .dropdown-toggle::after {
    display: none !important;
}

.dropTopHead {
    width: 25rem;
    display: none;
    transform: translate(0px, 50px);
}
.dropTopHead.show {
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.dropTitle {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid var(--dividerColor);
}
.dropTitle h5 {
    font-size: var(--fontSize12);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
}
.dropBody {
    height: 22.2rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.dropBodyMsg {
    height: 16rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.dropBodyProfile {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
}
.dropImgIcon {
    width: 2.5rem;
    text-align: center;
    margin-right: .5rem;
}
.dropImgIcon img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 100%;
    -moz-border-radius: 100%;
}
.dropImgIcon .notif-icon-2131 {
    font-size: 1rem;
    background: var(--blueColor7);
    color: var(--blueColor5);
    padding: .7rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
}
.dropText {
    display: block;
}
.dropText p {
    font-size: var(--fontSize8);
    font-weight: var(--weightNormal);
    color: var(--whiteColor);
    margin-bottom: 0;
}
.dropText .message-notification {
    font-size: var(--fontSize7);
    color: var(--blueColor8);
    margin-bottom: 0;
}
.dropText span {
    font-size: var(--fontSize7);
    font-weight: var(--weightStandard);
    color: var(--blueColor7);
}
.dropTopHeadUsr {
    display: none;
    transform: translate(-14px, 50px);
}
.dropTopHeadUsr.show {
    padding: 0;    
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.dropTopHeadUsr {
    width: 10rem;
}
.dropTopHeadUsr .dropdown-item {
    white-space: normal;
    padding: .5rem 1rem;
    border-bottom: 1px solid var(--dividerColor);
    cursor: pointer;
}
.dropTopHeadUsr .dropdown-item:last-child {
    border-bottom: 0;
}
.dropTopHeadUsr .dropdown-item:hover, .dropTopHeadUsr .dropdown-item:focus, .dropTopHeadUsr .dropdown-item:active {
    background-color: var(--blueColor2);
}
.dropTopHeadUsr span {
    color: var(--whiteColor);
}
.dropTopHeadUsr span:hover {
    color: var(--whiteColor);
}
.dropTopHead .dropdown-item {
    white-space: normal;
    padding: 1rem;
    border-bottom: 1px solid var(--dividerColor);
    cursor: pointer;
}
.dropTopHead .dropdown-item:last-child {
    border-bottom: 0;
}
.dropTopHead .dropdown-item:hover, .dropTopHead .dropdown-item:focus, .dropTopHead .dropdown-item:active {
    background-color: var(--blueColor2);
}
.dropSearchInput {
    padding: 1rem;
    border-bottom: 1px solid var(--dividerColor);
}
.ncb {
    margin-top: .3rem;
}
.ncb .btnLight {
    padding: 7px 9px 6px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: var(--llColor);
}

.dropdown-item.active:hover{
    background-color: var(--llColorHover);
}

.loading-card{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-item .loading-ll-gif{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.loading-item .loading-ll-gif-small{
    background: rgba($color: #000000, $alpha: 0) !important;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-bottom: 20px;
}


.loading-item{
    height: auto;
    width: 100%;
    border: none;
}

.loading-item:hover{
    box-shadow: none;
}

.peopledetail-abs{
    position: absolute;
    height: 100%;
    width: 100%;
}