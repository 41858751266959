.loading-container{
    width: 100%;
    height: 100vh;
    background-color: #000000ec;
    position: absolute;
    z-index: 9999;
}

.component-loading-customize-homepage{
    position: absolute;
    background-color: #000000b2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-custom{
    height: 3rem;
    width: 3rem;
}

.spinner-border{
    border: 2px solid #ea7c1e;
    border-right: transparent;
    border-left: transparent;
}

.hidden-loading {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.spinner-custom-btn{
    height: 18px;
    width: 18px;
    border: 2px solid white;
    border-right: transparent;
    border-left: transparent;
}

.spinner-custom-btn-poll{
    height: 13px;
    width: 13px;
    border: 1px solid var(--blackColor);
    border-right: transparent;
    border-left: transparent;
}